import * as React from 'react';
import { TableHead } from '@material-ui/core';
import { GridHeadProps } from '../../types';

const GridHead = React.forwardRef<HTMLTableSectionElement, GridHeadProps>(function GridHead ({
  className,
  children,
  size = 'medium',
  testId,
  ...props
}, ref) {

  return (
    <TableHead
      {...props}
      className={className}
      data-component="onx-grid-head"
      data-test-id={testId}
      ref={ref}>
      { 
        React.Children.map(children,
          (child) => React.isValidElement(child) ? React.cloneElement(child, { size }) : child
        )
      }
    </TableHead>
  );
});

export default GridHead;
