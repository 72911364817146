import { BasePaletteKey, DerivedPaletteKey, PaletteConfig } from "../types";

export const baseColorKeys: Array<BasePaletteKey> = [
  'ink',
  'canvas',
  'brandInk',
  'brandCanvas',
  'chalk',
  'system1',
  'system2',
  'system3',
  'sell',
  'buy',
  'alert1',
  'alert2',
  'alert3',
  'affirm',
  'border',
  'borderStrong',
  'hover',
];

export const derivedColorKeys: Array<DerivedPaletteKey> = [
  'page',
  'canvasSolid',
  'inkSolid',
  'borderSolid',
  'borderTrans',
  'borderDivider',
  'borderMedium',
  'brandBorderTrans',
  'brandBorderDivider',
  'bkg1',
  'bkg2',
  'bkg3',
  'hov1',
  'hov2',
  'level1',
  'level2',
  'level3',
  'level4',
  'brandLevel1',
  'brandLevel2',
  'brandLevel3',
  'brandLevel4',
  'chalk1',
  'chalk2',
  'chalk3',
  'chalk4',
];

export const colors: PaletteConfig = {
  dark: {
    ink: '#FFFFFF',
    canvas:'#050B14',
    brandInk: '#FFFFFF',
    brandCanvas: '#084C93',
    chalk: '#FFFFFF',
    system1: '#1D55A3',
    system2: '#1565D3',
    system3: '#C85422',
    sell: '#D96736',
    buy: '#2666AA',
    alert1: '#DC3434',
    alert2: '#C85422',
    alert3: '#C9A112',
    affirm: '#0EA05A',
    border: '#4B4F55',
    borderStrong: '#989DA1',
    hover: '#0F467E99',
    page: {
      backgroundColor: 'canvas',
      baseColor: 'ink',
      opacity: 2,
    },
    canvasSolid: {
      baseColor: 'canvas',
    },
    inkSolid: {
      baseColor: 'ink',
    },
    borderSolid: {
      baseColor: 'border'
    },
    borderTrans: {
      baseColor: 'ink',
      opacity: 30,
    },
    borderDivider: {
      baseColor: 'ink',
      opacity: 16,
    },
    borderMedium: {
      baseColor: 'ink',
      opacity: 45,
    },
    brandBorderTrans: {
      baseColor: 'brandInk',
      opacity: 30,
    },
    brandBorderDivider: {
      baseColor: 'brandInk',
      opacity: 16,
    },
    bkg1: {
      baseColor: 'ink',
      opacity: 2,
    },
    bkg2: {
      baseColor: 'ink',
      opacity: 4,
    },
    bkg3: {
      baseColor: 'ink',
      opacity: 8,
    },
    hov1: {
      baseColor: 'ink',
      opacity: 12,
    },
    hov2: {
      baseColor: 'ink',
      opacity: 16,
    },
    level1: {
      baseColor: 'ink',
      opacity: 75,
    },
    level2: {
      baseColor: 'ink',
      opacity: 65,
    },
    level3: {
      baseColor: 'ink',
      opacity: 45,
    },
    level4: {
      baseColor: 'ink',
      opacity: 30,
    },
    brandLevel1: {
      baseColor: 'brandInk',
      opacity: 90,
    },
    brandLevel2: {
      baseColor: 'brandInk',
      opacity: 75,
    },
    brandLevel3: {
      baseColor: 'brandInk',
      opacity: 55,
    },
    brandLevel4: {
      baseColor: 'brandInk',
      opacity: 35,
    },
    chalk1: {
      baseColor: 'chalk',
      opacity: 100,
    },
    chalk2: {
      baseColor: 'chalk',
      opacity: 75,
    },
    chalk3: {
      baseColor: 'chalk',
      opacity: 55,
    },
    chalk4: {
      baseColor: 'chalk',
      opacity: 35,
    },
  },
  light: {
    ink: '#1C365D',
    canvas: '#FFFFFF',
    brandInk: '#FFFFFF',
    brandCanvas: '#1C365D',
    chalk: '#FFFFFF',
    system1: '#1A4B90',
    system2: '#1565D3',
    system3: '#E7680C',
    sell: '#EF7039',
    buy: '#2A6DB5',
    alert1: '#DC3434',
    alert2: '#E7680C',
    alert3: '#FFC700',
    affirm: '#0EA05A',
    border: '#BFC7D2',
    borderStrong: '#60728E',
    hover: '#1565D326',
    page: {
      backgroundColor: 'canvas',
      baseColor: 'ink',
      opacity: 2,
    },
    canvasSolid: {
      baseColor: 'canvas',
    },
    inkSolid: {
      baseColor: 'ink',
    },
    borderSolid: {
      baseColor: 'border'
    },
    borderTrans: {
      baseColor: 'ink',
      opacity: 30,
    },
    borderDivider: {
      baseColor: 'ink',
      opacity: 16,
    },
    borderMedium: {
      baseColor: 'ink',
      opacity: 50,
    },
    brandBorderTrans: {
      baseColor: 'brandInk',
      opacity: 30,
    },
    brandBorderDivider: {
      baseColor: 'brandInk',
      opacity: 16,
    },
    bkg1: {
      baseColor: 'ink',
      opacity: 2,
    },
    bkg2: {
      baseColor: 'ink',
      opacity: 4,
    },
    bkg3: {
      baseColor: 'ink',
      opacity: 8,
    },
    hov1: {
      baseColor: 'ink',
      opacity: 12,
    },
    hov2: {
      baseColor: 'ink',
      opacity: 16,
    },
    level1: {
      baseColor: 'ink',
      opacity: 90,
    },
    level2: {
      baseColor: 'ink',
      opacity: 75,
    },
    level3: {
      baseColor: 'ink',
      opacity: 55,
    },
    level4: {
      baseColor: 'ink',
      opacity: 35,
    },
    brandLevel1: {
      baseColor: 'brandInk',
      opacity: 90,
    },
    brandLevel2: {
      baseColor: 'brandInk',
      opacity: 75,
    },
    brandLevel3: {
      baseColor: 'brandInk',
      opacity: 55,
    },
    brandLevel4: {
      baseColor: 'brandInk',
      opacity: 35,
    },
    chalk1: {
      baseColor: 'chalk',
      opacity: 100,
    },
    chalk2: {
      baseColor: 'chalk',
      opacity: 75,
    },
    chalk3: {
      baseColor: 'chalk',
      opacity: 55,
    },
    chalk4: {
      baseColor: 'chalk',
      opacity: 35,
    },
  },
};

export default colors;
