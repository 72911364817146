import * as React from 'react';
import clsx from 'clsx';
import { TableRow } from '@material-ui/core';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { GridRowProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    hoverState: {
      '&:hover': {
        backgroundColor: palette.hover.main,
      },
    },
    clickable: {
      '&:hover': {
        backgroundColor: palette.bkg2.main,
        cursor: 'pointer',
      },
    },
    selected: {
      backgroundColor: `${palette.bkg3.main} !important`,
      '&:hover': {
        backgroundColor: `${palette.bkg3.main} !important`,
      },
    },
    visible: {
      display: 'table-row',
    },
    hidden: {
      display: 'none',
    },
    'dentLevel-0': {
      '& .MuiTableCell-body:first-child': {
        paddingLeft: '20px',
      },
    },
    'dentLevel-1': {
      '& .MuiTableCell-body:first-child': {
        paddingLeft: '38px',
      },
    },
    'dentLevel-2': {
      '& .MuiTableCell-body:first-child': {
        paddingLeft: '56px',
      },
    },
    'dentLevel-3': {
      '& .MuiTableCell-body:first-child': {
        paddingLeft: '74px',
      },
    },
    'variant-0': {
      backgroundColor: palette.canvas.main,
      '& .MuiTableCell-body': {
        color: palette.level2.main,
      },
    },
    'variant-1': {
      backgroundColor: palette.bkg2.main,
      '& .MuiTableCell-body': {
        color: palette.level2.main,
      }
    },
    'variant-2': {
      backgroundColor: palette.bkg3.main,
      '& .MuiTableCell-body': {
        color: palette.level1.main,
      }
    },
    'variant-3': {
      backgroundColor: palette.hov2.main,
      '& .MuiTableCell-body': {
        ...fonts.displayFonts.xSmallStrong,
        color: palette.level1.main,
      }
    },
  };
}, { name: 'OnxGridRow', defaultTheme: defaultLightTheme})

const GridRow = React.forwardRef<HTMLTableRowElement, GridRowProps>(function GridRow ({
  children,
  className,
  dentLevel,
  header = false,
  visible = true,
  onClick,
  selected = false,
  size = 'medium',
  testId,
  variant = 0,
  ...props
}, ref) {

  const classes = useStyles();

  return (
    <TableRow
      {...props}
      classes={{
        root: clsx(
          typeof dentLevel === 'number' && classes[`dentLevel-${dentLevel}` as keyof typeof classes],
          classes[`variant-${variant}` as keyof typeof classes],
          !header && classes.hoverState,
          onClick && classes.clickable,
          visible ? classes.visible : classes.hidden,
        ),
        selected: classes.selected,
      }}
      className={className}
      data-component="onx-grid-row"
      data-test-id={testId}
      onClick={onClick}
      ref={ref}
      selected={selected}>
      { 
        React.Children.map(children,
          (child) => React.isValidElement(child) ? React.cloneElement(child, { size }) : child
        )
      }
    </TableRow>
  );
});

export default GridRow;
