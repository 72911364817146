import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { 
  CreditLimitProps, 
  CreditLimitStyleProps, 
  CreditLimitClassKey, 
} from 'types';
import { 
  defaultLightTheme as defaultTheme,
  makeStyles 
} from '../../themes';
import { fonts } from '../../constants';
import { clamp } from '../../utils';

const BAR_LENGTH = 80;
const BAR_HEIGHT = 6;

const useStyles = makeStyles<CreditLimitStyleProps, CreditLimitClassKey>(({ palette }) => ({
  root: {
    width: `${BAR_LENGTH}px`,
    margin: '0 12px',
  },
  bar: {
    height: `${BAR_HEIGHT}px`,
  },
  container: {
    display: 'block',
  },
  empty: {
    fill: 'none',
    stroke: palette.borderDivider.main,
    strokeWidth: '1px',
  },
  filled: {
    transition: 'width .4s linear, fill .4s linear',
    fillOpacity: ({ disabled }) => disabled ? 0.3 : 1,
    fill: ({ barColor, percent }) => {
      if (barColor) return barColor;
        
      if (percent < 50) {
        return palette.affirm.main;
      } else if (percent < 75) {
        return palette.alert3.main;
      } else {
        return palette.alert1.main;
      }
    },
  },
  label: {
    ...fonts.displayFonts.xSmall,
    textAlign: 'end',
    color: ({ disabled }) => disabled ? palette.level4.main : palette.level2.main,
  },
}), { defaultTheme, name: 'OnxCreditLimit' });

const CreditLimit = React.forwardRef<HTMLDivElement, CreditLimitProps>(
  function CreditLimit({
    barColor,
    className,
    disabled = false,
    label = '$ 0',
    percent = 0,
    testId,
    ...props
  }, ref) {
    const percentFilled = clamp(percent, 0, 100);
    const classes = useStyles({ percent: percentFilled, barColor, disabled });

    return (
      <div
        className={clsx(classes.root, className)}
        data-component="onx-credit-limit"
        data-test-id={testId}
        ref={ref}
        {...props}
      >
        <Typography className={classes.label}>
          {label}
        </Typography>
        <svg 
          xmlns="http://www.w3.org/2000/svg"
          version='1.1'
          className={classes.container}
          viewBox={`0 0 ${BAR_LENGTH} ${BAR_HEIGHT}`}
        >
          <rect width='100%' className={clsx(classes.bar, classes.empty)}/>
          <rect width={`${percentFilled}%`} className={clsx(classes.bar, classes.filled)} />
        </svg>
      </div>
    );
  }
)

export default CreditLimit;
