import * as React from 'react';
import clsx from 'clsx';
import {
  AlertCircleIcon,
  AlertInfoIcon,
  AlertOctagonIcon,
  AlertTriangleIcon
} from '../../Icon';
import { fonts } from '../../../constants';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { NotificationItemProps } from '../../../types';

const useNotificationItemStyles = makeStyles((theme) => {
  const { palette } = theme;

  return {
    notificationItem: {
      backgroundColor: palette.canvas.main,
      borderBottom: `1px solid ${palette.border.main}`,
      display: 'flex',
      minHeight: '75px',
      padding: '8px 16px 8px 8px',
      width: '100%',
      '&:hover': {
        backgroundColor: palette.bkg2.main,
        cursor: 'pointer',
      },
    },
    notificationOld: {
      backgroundColor: palette.bkg3.main,
      '&:hover': {
        backgroundColor: palette.hov1.main,
      },
    },
    notificationIcon: {
      ...fonts.displayFonts.xxLarge,
      marginRight: '8px',
    },
    notificationDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      width: '100%',
    },
    titleContainer: {
      alignItems: 'baseline',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: palette.level2.main,
      ...fonts.displayFonts.capsSmall,
    },
    date: {
      color: palette.level3.main,
      ...fonts.displayFonts.small,
    },
    description: {
      color: palette.level1.main,
      ...fonts.displayFonts.smallStrong,
    },
    information: {
      color: palette.level3.main,
      ...fonts.displayFonts.small,
    },
    informationAlt: {
      ...fonts.displayFonts.smallItalic,
    },
    errorIcon: {
      color: palette.alert1.main,
    },
    infoIcon: {
      color: palette.system2.main,
    },
    successIcon: {
      color: palette.affirm.main,
    },
    warningIcon: {
      color: palette.alert2.main,
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxNotifications' });

function NotificationItem({
  description,
  information,
  informationAlt,
  onClick,
  read,
  timestamp,
  title,
  type,
  ...props
}: NotificationItemProps) {
  const classes = useNotificationItemStyles();

  const Icons = {
    error: <AlertOctagonIcon className={classes.errorIcon} />,
    info: <AlertInfoIcon className={classes.infoIcon} />,
    success: <AlertCircleIcon className={classes.successIcon} />,
    warning: <AlertTriangleIcon className={classes.warningIcon} />
  }

  return (
    <div
      className={clsx(
        classes.notificationItem,
        read && classes.notificationOld
      )}
      onClick={onClick}
    >
      <div className={classes.notificationIcon}>
        {Icons[type]}
      </div>
      <div className={classes.notificationDetails}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>
            {title}
          </span>
          <span className={classes.date}>
            {timestamp}
          </span>
        </div>
        <div className={classes.description}>
          {description}
        </div>
        <div className={classes.information}>
          {information} <span className={classes.informationAlt}>{informationAlt}</span></div>
      </div>
    </div>
  );
}

export default NotificationItem;