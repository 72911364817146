import * as React from 'react';
import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { GridCellProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      ...fonts.displayFonts.xSmall,
      borderBottomColor: palette.borderDivider.main,
      padding: '8px 12px',
      verticalAlign: 'middle',
      width: ({ width }: Pick<GridCellProps, 'width'>) => width ?? 'auto',
    },
    pinnedLeft: {
      backgroundColor: palette.canvas.main,
      borderRight: `1px solid ${palette.borderDivider.main}`,
      left: 0,
      position: 'sticky',
      zIndex: 2
    },
    small: {
      ...fonts.displayFonts.xxSmall,
      padding: '6px 8px',
    },
    borderRight: {
      borderRight: `1px solid ${palette.borderDivider.main}`,
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  };
}, { name: 'OnxGridCell', defaultTheme: defaultLightTheme});

const GridCell = React.forwardRef<HTMLTableCellElement, GridCellProps>(function GridCell ({
  align,
  children,
  className,
  ellipsis = false,
  pinned = false,
  pinnedLeft = false,
  size = 'medium',
  showBorder = false,
  testId,
  type,
  width,
  ...props
}, ref) {

  const classes = useStyles({ width });
  
  return (
    <TableCell
      {...props}
      align={align ? align : 'left'}
      className={className}
      classes={{
        root: clsx(
          classes.root,
          size === 'small' && classes.small,
          (pinned || pinnedLeft) && classes.pinnedLeft,
          showBorder && classes.borderRight,
          ellipsis && classes.ellipsis
        )
      }}
      data-component="onx-grid-cell"
      data-test-id={testId}
      ref={ref}>
      {children}
    </TableCell>
  );
});

export default GridCell;
