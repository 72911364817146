import {
  MutableRefObject,
  useCallback,
  useEffect,
  useState
} from 'react';

export default function useInfiniteLoader (
  hasMore: boolean,
  infiniteLoading: boolean,
  targetRef: MutableRefObject<HTMLDivElement | null | undefined>,
  threshold: number = 0.8,
  loadMore?: () => Promise<void>
) {

  const [isLoading, setIsLoading] = useState(false);

  const onEventListener = useCallback(async (e) => {
    if (infiniteLoading && !isLoading && hasMore && loadMore) {

      const scrollPosition = e.target.scrollTop + e.target.clientHeight;
      const thresholdValue = e.target.scrollHeight * threshold;
      if (scrollPosition >= thresholdValue) {
        setIsLoading(true);
        
        try {
          await loadMore();
        } catch (e) {
          console.error('There was an error loading more results.', e);
        } finally {
          setIsLoading(false);
        }
      }
    }
  }, [infiniteLoading, isLoading, hasMore, loadMore, threshold]);

  useEffect(() => {
    const target = targetRef?.current;

    if (target) target.addEventListener('scroll', onEventListener);

    return () => {
      if (target) target.removeEventListener('scroll', onEventListener);
    }
  }, [onEventListener, targetRef]);
}
