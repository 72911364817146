import React from 'react';
import clsx from 'clsx';
import { defaultLightTheme, makeStyles } from '../../themes';
import { IconButtonGroupProps } from '../../types';

const useStyles = makeStyles(() => {
  return {
    horizontalGroup: {
      display: 'flex',
      flexDirection: 'row',
      '& > *:not(:last-child)': {
        marginRight: '4px',
      },
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxIconButtonGroup' });

const IconButtonGroup = ({
  className,
  children,
  testId,
  ...props
}: IconButtonGroupProps) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.horizontalGroup,
        className
      )}
      data-component='onx-icon-button-group'
      data-test-id={testId}
    >
      {children}
    </div>
  );
};

export default IconButtonGroup;
