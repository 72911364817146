import * as React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import {
  Tab,
  Tabs,
} from '@material-ui/core';
import {
  fonts,
  opacities
} from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import {
  CustomTabProps,
  SectionTabsProps,
} from '../../types';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    tabTextColorInherit: {
      color: palette.level3.main,
      opacity: 1,
      '&:hover': {
        color: palette.level2.main,
      },
      '&$selected': {
        color: palette.level1.main,
      },
    },
    tabRoot: {
      ...fonts.displayFonts.capsMedium,
      borderRight: `1px solid ${palette.border.main}`,
      borderBottom: `1px solid ${palette.border.main}`,
      '&$selected': {
        backgroundColor: palette.canvas.main,
      },
    },
    tabDisabled: {
      backgroundColor: `${palette.brandCanvas.main}${opacities[8]}`,
    },
    labelContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      '&::after': {
        content: "''",
        position: 'absolute',
        left: 0,
        width: '100%',
      },
    },
    counter: {
      border: `1px solid ${palette.level4.main}`,
      borderRadius: '4px',
      color: palette.level3.main,
      ...fonts.displayFonts.counterMedium,
      marginLeft: '4px',
      padding: '0px 3px',
    },
    tab: {
      borderBottom: `1px solid ${palette.border.main}`,
      borderRightColor: palette.border.main,
      minWidth: 100,
    },
    activeTab: {
      '&.Mui-selected': {
        backgroundColor: palette.canvas.main,
        borderBottom: '1px solid transparent',
        '&:hover': {
          '& .MuiTab-wrapper > div::after': {
            background: 'transparent',
          },
        },
        '& $counter': {
          color: palette.level2.main,
        }
      },
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxSectionTabs' });

function SectionTabs({
  classes: inheritedClasses,
  className,
  onChange,
  TabProps = {},
  tabs,
  testId,
  value,
  ...props
}: SectionTabsProps) {
  const activeTabRef = useRef<HTMLDivElement>();
  const activeLabelRef = useRef<any>();
  const [indicatorScale, setIndicatorScale] = useState<number>(1);

  const calculateIndicatorScale = useCallback(() => {
    return Math.min(
      (activeLabelRef.current?.clientWidth || 1) /
      (activeTabRef.current?.clientWidth || 1),
      1
    );
  }, []);

  useEffect(() => {
    setIndicatorScale(calculateIndicatorScale());
  }, [calculateIndicatorScale]);

  const classes = useStyles();

  const TabIndicatorProps = useMemo(() => {
    return {
        style: {
        transform: `translate(0, -10px) scaleX(${indicatorScale})`,
      }
    }
  }, [indicatorScale]);

  const handleOnChange = (event: React.ChangeEvent<{}>, value: number) => {
    onChange(event, value);
    setTimeout(() => {
      setIndicatorScale(calculateIndicatorScale());
    }, 0);
  };

  return (
    <Tabs
      classes={{
        root: clsx(inheritedClasses.root, className),
        indicator: inheritedClasses.indicator
      }}
      data-component="onx-section-tabs"
      data-test-id={testId}
      onChange={(event, value) => handleOnChange(event, value)}
      TabIndicatorProps={TabIndicatorProps}
      value={value}
      {...props}
    >
      {
        tabs.map((tab: CustomTabProps, i) => {
          const isActive = value === i;
          return (
            <Tab
              key={i}
              {...tab}
              classes={{
                root: clsx(
                  classes.tabRoot,
                  classes.tab,
                  inheritedClasses.tab,
                  isActive && classes.activeTab,
                  isActive && inheritedClasses.activeTab
                ),
                textColorInherit: classes.tabTextColorInherit,
              }}
              disableRipple
              label={
                <div
                  ref={isActive ? activeLabelRef : undefined}
                  className={clsx(
                    classes.labelContainer,
                    inheritedClasses.labelContainer
                  )}
                >
                  {tab.label}
                  {typeof tab.counter !== 'undefined' && (
                    <div className={classes.counter}>{tab.counter}</div>
                  )}
                </div>
              }
              innerRef={isActive ? activeTabRef : undefined}
              {...TabProps}
            />
          );
        })
      }
    </Tabs>
  );
}

export default SectionTabs;
