import * as React from 'react';
import { PanelTabs } from '../../SectionTabs/PanelTabs';
import { SectionHeader } from '..';
import { fonts } from '../../../constants';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { GenericSectionHeaderProps } from "../../../types";

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      backgroundColor: palette.bkg1.main,
      width: '100%',
    },
    heading: {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      height: '42px',
      borderLeft: 'none',
      borderTop: 'none',
      borderRight: 'none',
    },
    titleContainer: {
      height: '42px',
      padding: '11px 20px 10px 20px',
    },
    title: {
      ...fonts.displayFonts.medium,
    },
    extraContent: {
      height: '42px',
    },
    extraContentItems: {
      alignItems: 'center',
      color: palette.level1.main,
      display: 'inline-flex',
      ...fonts.displayFonts.medium,
      '& > span': {
        marginRight: '24px',
      },
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxPanelHead' });

function PanelHead({
  className,
  testId,
  ...props
}: GenericSectionHeaderProps) {
  const classes = useStyles();

  const TabsComponent = props.TabsProps ? <PanelTabs {...props.TabsProps} /> : null;

  return <SectionHeader
    classes={classes}
    className={className}
    data-component="onx-panel-head"
    data-test-id={testId}
    tabs={TabsComponent}
    {...props}
  />;
}

export default PanelHead;
