import React from 'react';
import clsx from 'clsx';
import { defaultLightTheme, makeStyles } from '../../themes';
import { CheckboxGroupProps } from '../../types';

const useStyles = makeStyles(() => {
  return {
    verticalGroup: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:last-child)': {
        marginBottom: '4px',
      },
    },
    horizontalGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxCheckboxGroup' });

function CheckboxGroup(props: CheckboxGroupProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        props.direction === 'vertical'
          ? classes.verticalGroup
          : classes.horizontalGroup,
        props.className
      )}
      data-component='onx-checkbox-group'
      data-test-id={props.testId}
    >
      {props.children}
    </div>
  );
}

export default CheckboxGroup;
