import {
  DatePickerProps,
  KeyboardDatePickerProps
} from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';

interface DatePickerBaseProps {
  className?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  label?: any;
  variant?: 'inline' | 'static';
  testId?: string;
}

export type StaticDatePickerProps = DatePickerBaseProps & DatePickerProps;
export type InlineDatePickerProps = DatePickerBaseProps & KeyboardDatePickerProps;

export type DateTimePickerToolbarProps = ToolbarComponentProps & {
  className?: string;
  onChange: (date: Date) => void;
};

export enum Meridiem {
  AM,
  PM
}
