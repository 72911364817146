import * as React from 'react';
import { useState } from 'react';
import { PopupFilter } from '../../Filters';
import { Menu } from '../../Menu';
import {
  GridFilterDiscreteListProps,
  MenuListItem
} from '../../../types';

function GridFilterDiscreteList<T> ({
  className,
  filterOptions,
  iconSize = 'xSmall',
  multiple = false,
  onClose,
  onOpen,
  value,
  showClear = false,
  showSelectAll = false,
  testId
}: GridFilterDiscreteListProps<T>) {
  const [open, setOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [filterValue, setFilterValue] = useState<T | undefined>(value);

  const handleOpen = (e: React.MouseEvent) => {
    setMenuAnchor(e.currentTarget);
    setOpen(true);
    onOpen && onOpen();
  }

  const handleClose = () => {
    setMenuAnchor(null);
    setOpen(false);
    onClose(filterValue);
  }

  const handleChange = (selectedItems: unknown, close: boolean = false) => {
    setFilterValue(selectedItems as T);
    if (close) {
      setMenuAnchor(null);
      setOpen(false);
      onClose(selectedItems);
    }
  }

  return (
    <PopupFilter
      active={Array.isArray(filterValue) ? filterValue.length > 0 : !!filterValue}
      className={className}
      data-component="onx-grid-filter-discrete-list"
      data-test-id={testId}
      onClick={handleOpen}
      size={iconSize}>
      <Menu
        anchorEl={menuAnchor}
        customTrigger={true}
        items={filterOptions as MenuListItem[]}
        multiple={multiple}
        open={open}
        onChange={handleChange}
        onClose={handleClose}
        showClear={showClear}
        showSelectAll={showSelectAll}
        value={filterValue}
      />
    </PopupFilter>
  );

}

export default GridFilterDiscreteList;
