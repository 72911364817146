import * as React from 'react';
import { TableBody } from '@material-ui/core';
import { GridBodyProps } from '../../types';

const GridBody = React.forwardRef<HTMLTableSectionElement, GridBodyProps>(function GridBody ({
  className,
  children,
  size = 'medium',
  testId,
  ...props
}, ref) {

  return (
    <TableBody
      {...props}
      className={className}
      data-component="onx-grid-body"
      data-test-id={testId}
      ref={ref}>
      { 
        React.Children.map(children,
          (child) => React.isValidElement(child) ? React.cloneElement(child, { size }) : child
        )
      }
    </TableBody>
  );
});

export default GridBody;
