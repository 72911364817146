import * as React from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { SheetPropsCustom } from '../../../types';
import { SheetHead } from '../../SectionHeader/SheetHead';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      alignItems: 'center',
      backgroundColor: palette.canvas.main,
      border: `1px solid ${palette.border.main}`,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 'unset',
      overflow: 'hidden',
      '&.MuiContainer-root':{
        paddingLeft: '0px',
        paddingRight: '0px',
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
    containerHead: {
      backgroundColor: palette.bkg2.main,
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      height: 'auto',
      width: '100%',
    },
    containerBody: {
      display: 'flex',
      height: 'calc(100% - 48px)',
      justifyContent: 'space-between',
      position: 'relative',
      width: '100%',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px'
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxSheet' });

const splitChildren = (children: SheetPropsCustom['children']) => {
  if (Array.isArray(children)) {
    const head = children.find(c => (c as React.ReactElement)?.type === SheetHead);
    const body = children.filter(c => (c as React.ReactElement)?.type !== SheetHead);
    return [head, body];
  }
  return [undefined, children];
}

const Sheet = ({
  bodyClassName,
  children,
  childrenHead,
  classes = {},
  className,
  testId,
  ...props
}: SheetPropsCustom) => {
  const styles = useStyles();
  const [head, body] = splitChildren(children);

  return (
    <Container
      {...props}
      className={clsx(
        styles.root,
        classes.root,
        className
      )}
      data-component="onx-sheet"
      date-test-id={testId}>
      {(head || childrenHead) && (
        <div className={clsx(styles.containerHead, classes.head)}>{head || childrenHead}</div>
      )}

      <div className={clsx(styles.containerBody, classes.body, bodyClassName)}>{body || children}</div>
    </Container>
  );
};

export default Sheet;
