/* eslint-disable */

"#if BUILD_MODE === 'lib'"

export * from './components';
export * from './constants';
export * from './themes';
export * from './types';

"#elif BUILD_MODE === 'app'"

import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from './themes';
import { BasePaletteKey } from './types';
import { normalizeHex } from './utils';
import App from './App';

const defaultDarkColors = {
  ink: '#FFFFFF',
  canvas:'#050B14',
  brand: '#1C365D',
  system1: '#1D55A3',
  system2: '#1565D3',
  sell: '#D96736',
  buy: '#2666AA',
  alert1: '#DC3434',
  alert2: '#D96736',
  alert3: '#C9A112',
  affirm: '#0EA05A',
  border: '#4B4F55',
  borderStrong: '#989DA1',
}

const defaultLightColors = {
  ink: '#1C365D',
  canvas: '#FFFFFF',
  brand: '#1C365D',
  system1: '#1A4B90',
  system2: '#1565D3',
  sell: '#EF7039',
  buy: '#2A6DB5',
  alert1: '#DC3434',
  alert2: '#E7680C',
  alert3: '#FFC700',
  affirm: '#0EA05A',
  border: '#BFC7D2',
  borderStrong: '#60728E',
}

const validColorHex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{4}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/

const Demo = () => {
  const [themeType, setThemeType] = useState<'light' | 'dark'>('dark')
  const [darkColors, setDarkColors] = useState<Partial<Record<BasePaletteKey, string>>>(defaultDarkColors)
  const [lightColors, setLightColors] = useState<Partial<Record<BasePaletteKey, string>>>(defaultLightColors)
  const colors = themeType === 'dark' ? darkColors : lightColors
  const setColors = themeType === 'dark' ? setDarkColors : setLightColors
  const [theme, setTheme] = useState(createTheme({ type: themeType, overrides: { colors } }))

  const toggleTheme = useCallback(() => {
    setThemeType(themeType === 'dark' ? 'light' : 'dark')
  }, [themeType])

  const setColor = (key: BasePaletteKey, color: string) => {
    color = normalizeHex(color);
    if (colors[key] !== color && validColorHex.test(color)) {
      setColors({ ...colors, [key]: color })
    }
  }

  useEffect(() => {
    setTheme(createTheme({ type: themeType, overrides: { colors } }))
  }, [themeType, colors])

  return (
    <ThemeProvider theme={theme} scoped={false}>
      <App theme={theme} toggleTheme={toggleTheme} colors={colors} setColor={setColor} />
    </ThemeProvider>
  )
}

ReactDOM.render(<Demo />, document.getElementById('root'))

"#endif"
