import React from 'react';
import { FiltersSelectorProps } from '../../types';
import DropdownSelector from '../DropdownSelector/DropdownSelector';

const FiltersDropdown: React.FC<FiltersSelectorProps> = ({
  className,
  onChange,
  options,
  testId,
  values = [],
  ...props
}) => {

  return (
    <DropdownSelector
      className={className}
      data-component="onx-filter-dropdown"
      date-test-id={testId}
      label="Add filter"
      multiple
      onChange={onChange as (value: unknown) => void}
      onClose={()=>{}}
      size="medium"
      value={values}
      items={options}
      {...props}
    />
  );
};

export default FiltersDropdown;
