import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { fonts } from '../../constants';
import { IconProps } from '../../types';
import SVG, { SVGType } from './svg';

const TempIcon = React.forwardRef<SVGSVGElement, IconProps>(({testId, ...props}, ref) => <SvgIcon ref={ref} data-test-id={testId} component={SVG.Close} {...props} />);
export type IconWithRef = typeof TempIcon;
export type IconsType = { [icon: string]: IconWithRef; };

const Icons = Object.keys(SVG).reduce((Icons: IconsType, icon) => {
  const Icon = SVG[icon as keyof SVGType];
  Icons[icon] = React.forwardRef<SVGSVGElement, IconProps>(({testId, fontSize = "inherit", size, ...props}, ref) => (
    <SvgIcon
      color="inherit"
      component={Icon}
      data-component={`onx-${icon}-icon`}
      data-test-id={testId}
      fontSize={fontSize}
      ref={ref}
      style={size ? { fontSize: fonts.displayFonts[size]?.lineHeight } : {}}
      {...props}
    />
  ))
  return Icons;
}, {});

export const AddIcon = Icons.Add;
export const AlertIcon = Icons.Alert;
export const AlertCircleIcon = Icons.AlertCircle;
export const AlertInfoIcon = Icons.AlertInfo;
export const AlertOctagonIcon = Icons.AlertOctagon;
export const AlertTriangleIcon = Icons.AlertTriangle;
export const AvatarIcon = Icons.Avatar;
export const BankIcon = Icons.Bank;
export const BellIcon = Icons.Bell;
export const CalendarRangeIcon = Icons.CalendarRange;
export const CalendarIcon = Icons.Calendar;
export const CancelIcon = Icons.Cancel;
export const CheckIcon = Icons.Check;
export const CheckCircleIcon = Icons.CheckCircle;
export const CheckboxMixIcon = Icons.CheckboxMix;
export const CheckboxOffIcon = Icons.CheckboxOff;
export const CheckboxOnIcon = Icons.CheckboxOn;
export const CheckboxSelectedIcon = Icons.CheckboxSelected;
export const ChevronLeftIcon = Icons.ChevronLeft;
export const ChevronRightIcon = Icons.ChevronRight;
export const ClockIcon = Icons.Clock;
export const CloseCircleIcon = Icons.CloseCircle;
export const CloseIcon = Icons.Close;
export const CogIcon = Icons.Cog;
export const CollapseIcon = Icons.Collapse;
export const DeleteIcon = Icons.Delete;
export const DownloadCloudIcon = Icons.DownloadCloud;
export const DownloadIcon = Icons.Download;
export const EditIcon = Icons.Edit;
export const EqualNotIcon = Icons.EqualNot;
export const EqualIcon = Icons.Equal;
export const ExpandIcon = Icons.Expand;
export const FavoriteIcon = Icons.Favorite;
export const FilterIcon = Icons.Filter;
export const FilterClearIcon = Icons.FilterClear;
export const GridIcon = Icons.Grid;
export const HamburgerIcon = Icons.Hamburger;
export const HideIcon = Icons.Hide;
export const HistoryIcon = Icons.History;
export const KeyboardIcon = Icons.Keyboard;
export const LockClosedIcon = Icons.LockClosed;
export const LockOpenIcon = Icons.LockOpen;
export const MailIcon = Icons.Mail;
export const MenuDownIcon = Icons.MenuDown;
export const MenuLeftIcon = Icons.MenuLeft;
export const MenuRightIcon = Icons.MenuRight;
export const MenuUpIcon = Icons.MenuUp;
export const MinusIcon = Icons.Minus;
export const PlaceholderIcon = Icons.Placeholder;
export const PlusIcon = Icons.Plus;
export const PriceDownIcon = Icons.PriceDown;
export const PriceUpIcon = Icons.PriceUp;
export const RefreshIcon = Icons.Refresh;
export const ResetIcon = Icons.Reset;
export const RowSingleIcon = Icons.RowSingle;
export const SaveIcon = Icons.Save;
export const ScreenCollapseIcon = Icons.ScreenCollapse;
export const ScreenExpandIcon = Icons.ScreenExpand;
export const SendIcon = Icons.Send;
export const ShowIcon = Icons.Show;
export const SortDownIcon = Icons.SortDown;
export const SortUpIcon = Icons.SortUp;
export const SwitchOffIcon = Icons.SwitchOff;
export const SwitchOnIcon = Icons.SwitchOn;
export const GreaterEqualIcon = Icons.GreaterEqual;
export const GreaterIcon = Icons.Greater;
export const LessEqualIcon = Icons.LessEqual;
export const LessIcon = Icons.Less;
export const UserIcon = Icons.User;
export const WalletIcon = Icons.Wallet;
export const WindowIcon = Icons.Window;
export const ZoomIcon = Icons.Zoom;

export default Icons
