import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  CalendarIcon,
  MenuLeftIcon,
  MenuRightIcon
} from '../Icon';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import {
  InlineDatePickerProps,
  StaticDatePickerProps
} from '../../types';

const useStyles = makeStyles(({ palette })=> {
  return {
    inlineDatePicker: {
      border: `1px solid ${palette.borderStrong.main}`,
      boxSizing: 'border-box',
      boxShadow: 'none',
      marginTop: '-32px',
      width: '252px',
    },
    inlineSmall: {
      marginTop: '-34px',
      width: '200px',
    },
    staticDatePicker: {
      border: `1px solid ${palette.borderStrong.main}`,
      borderRadius: '4px',
      boxShadow: 'none',
      boxSizing: 'border-box',
      width: '252px',
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        boxSizing: 'border-box',
        minWidth: '250px',
        width: '250px',
      },
    },
    staticSmall: {
      width: '200px',
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        minWidth: '200px',
        width: '200px',
      },
    },
    shared: {
      '& .MuiPickersBasePicker-container': {
        border: 'none',
        boxSizing: 'border-box',
        padding: '12px 16px',
        width: '250px',
        '& .MuiPickersBasePicker-pickerView': {
          overflowX: 'unset',
        },
      },
      '& .MuiPickersCalendarHeader-daysHeader': {
        height: '28px',
        maxHeight: '28px',
        '& .MuiTypography-root': {
          ...fonts.displayFonts.xSmallStrong,
          color: palette.level3.main,
        },
      },
      '& .MuiPickersCalendarHeader-switchHeader': {
        boxSizing: 'border-box',
        height: '32px',
        margin: 0,
        padding: '0 0 12px 0',
        '& .MuiTypography-root': {
          ...fonts.displayFonts.medium,
          color: palette.level1.main,
        },
        '& .MuiSvgIcon-root': {
          ...fonts.displayFonts.medium,
          color: palette.level2.main,
        }
      },
      '& .MuiPickersCalendar-week': {
        margin: `0 !important`,
        '& .MuiPickersDay-day': {
          height: '28px',
          margin: 0,
          width: '28px',
          '&:hover': {
            backgroundColor: palette.hov1.main,
          },
          '& .MuiTypography-root': {
            ...fonts.displayFonts.xSmallStrong,
            color: palette.level2.main,
          },
          '&.MuiPickersDay-hidden': {
            '& .MuiTypography-root': {
              color: palette.level4.main,
            },
          },
        },
        '& .MuiPickersDay-daySelected': {
          borderColor: palette.alert2.main,
          '& .MuiTypography-root': {
            color: `${palette.level1.main} !important`,
          },
        },
      },
      '& .MuiPickersCalendar-transitionContainer': {
        minHeight: '168px',
      },
    },
    sharedSmall: {
      '& .MuiPickersBasePicker-container': {
        padding: '12px 4px',
        width: '198px',
      },
      '& .MuiPickersCalendarHeader-daysHeader': {
        height: '26px',
        maxHeight: '26px',
      },
      '& .MuiPickersCalendar-week': {
        '& .MuiPickersDay-day': {
          height: '26px',
          width: '26px',
        },
      },
      '& .MuiPickersCalendar-transitionContainer': {
        minHeight: '156px',
      },
    },
    arrowButton: {
      '&:hover': {
        background: 'none',
      },
      padding: 0,
    },
  }
}, { name: 'OnxDatePicker', defaultTheme: defaultLightTheme });

class CustomDateFnsUtils extends DateFnsUtils {
  getWeekdays() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}

const DatePicker = ({
  className,
  disabled = false,
  disableFuture = false,
  format = 'dd-MMM-yyyy',
  label,
  onChange,
  size = 'medium',
  testId,
  variant = 'static',
  value = null,
  ...props
}: InlineDatePickerProps | StaticDatePickerProps) => {
  const [ hideLabel, setHideLabel ] = useState<boolean>(false);
  const [ dateValue, setDateValue ] = useState(value);
  const classes = useStyles();

  const toggleLabel = () => {
    setHideLabel(!hideLabel);
  }

  const handleDateChange = (date: Date | null) => {
    setDateValue(date);
    if (onChange) onChange(date);
  }

  // Allows only a-z and 0-9 char
  const refusePattern = /[^a-zA-Z0-9]+/gi;

  return variant === 'static' ? (
    <div className={clsx(
      classes.staticDatePicker,
      classes.shared,
      size === 'small' && classes.staticSmall,
      size === 'small' && classes.sharedSmall
    )}>
      <MuiPickersUtilsProvider utils={CustomDateFnsUtils}>
        <MuiDatePicker
          {...props}
          className={className}
          data-component="onx-datepicker"
          date-test-id={testId}
          disabled={disabled}
          disableFuture={disableFuture}
          disableToolbar
          format={format}
          leftArrowButtonProps={{
            classes: {
              root: classes.arrowButton,
            }
          }}
          leftArrowIcon={<MenuLeftIcon size="medium" />}
          onChange={onChange}
          rightArrowButtonProps={{
            classes: {
              root: classes.arrowButton,
            }
          }}
          rightArrowIcon={<MenuRightIcon size="medium" />}
          value={value}
          variant="static"
        />
      </MuiPickersUtilsProvider>
    </div>
  ) : (
    <MuiPickersUtilsProvider utils={CustomDateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        autoOk
        className={className}
        data-component="onx-datepicker"
        date-test-id={testId}
        disabled={disabled}
        disableFuture={disableFuture}
        disableToolbar
        format={format}
        inputVariant="outlined"
        KeyboardButtonProps={{
          classes: {
            root: classes.arrowButton,
          }
        }}
        keyboardIcon={<CalendarIcon size="medium" />}
        label={!hideLabel ? label : ''}
        leftArrowButtonProps={{
          classes: {
            root: classes.arrowButton,
          }
        }}
        leftArrowIcon={<MenuLeftIcon size="medium" />}
        onChange={date => handleDateChange(date)}
        onClose={toggleLabel}
        onOpen={toggleLabel}
        PopoverProps={{
          ...props.PopoverProps && props.PopoverProps,
          classes: {
            paper: clsx(
              classes.inlineDatePicker,
              classes.shared,
              size === 'small' && [classes.inlineSmall, classes.sharedSmall],
              size === 'small' && classes.sharedSmall
            )
          }
        }}
        refuse={refusePattern}
        rightArrowButtonProps={{
          classes: {
            root: classes.arrowButton,
          }
        }}
        rightArrowIcon={<MenuRightIcon size="medium" />}
        value={dateValue}
        variant={variant}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
