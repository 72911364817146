import * as React from 'react';
import { ReactComponent as Add } from './Add.svg';
import { ReactComponent as Alert } from './Alert.svg';
import { ReactComponent as AlertCircle } from './Alert-circle.svg';
import { ReactComponent as AlertInfo } from './Alert-info.svg';
import { ReactComponent as AlertOctagon } from './Alert-octagon.svg';
import { ReactComponent as AlertTriangle } from './Alert-triangle.svg';
import { ReactComponent as Avatar } from './Avatar.svg';
import { ReactComponent as Bank } from './Bank.svg';
import { ReactComponent as Bell } from './Bell.svg';
import { ReactComponent as CalendarRange } from './Calendar-range.svg';
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Cancel } from './Cancel.svg';
import { ReactComponent as Check } from './Check.svg';
import { ReactComponent as CheckCircle } from './Check-circle.svg';
import { ReactComponent as CheckboxMix } from './Chkbox-mix.svg';
import { ReactComponent as CheckboxOff } from './Chkbox-off.svg';
import { ReactComponent as CheckboxOn } from './Chkbox-on.svg';
import { ReactComponent as CheckboxSelected } from './Chkbox-selected.svg';
import { ReactComponent as ChevronLeft } from './Cheveron-left.svg';
import { ReactComponent as ChevronRight } from './Cheveron-right.svg';
import { ReactComponent as Clock } from './Clock.svg';
import { ReactComponent as CloseCircle } from './Close-circle.svg';
import { ReactComponent as Close } from './Close.svg';
import { ReactComponent as Cog } from './Cog.svg';
import { ReactComponent as Collapse } from './Collapse.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { ReactComponent as DownloadCloud } from './Download-cloud.svg';
import { ReactComponent as Download } from './Download.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { ReactComponent as EqualNot } from './Equal-not.svg';
import { ReactComponent as Equal } from './Equal.svg';
import { ReactComponent as Expand } from './Expand.svg';
import { ReactComponent as Favorite } from './Favorite.svg';
import { ReactComponent as Filter } from './Filter.svg';
import { ReactComponent as FilterClear } from './Filter-clear.svg'
import { ReactComponent as Grid } from './Grid.svg';
import { ReactComponent as Hamburger } from './Hamburger.svg';
import { ReactComponent as Hide } from './Hide.svg';
import { ReactComponent as History } from './History.svg';
import { ReactComponent as Keyboard } from './Keyboard.svg';
import { ReactComponent as LockClosed } from './Lock-closed.svg';
import { ReactComponent as LockOpen } from './Lock-open.svg';
import { ReactComponent as Mail } from './Mail.svg';
import { ReactComponent as MenuDown } from './Menu-down.svg';
import { ReactComponent as MenuLeft } from './Menu-left.svg';
import { ReactComponent as MenuRight } from './Menu-right.svg';
import { ReactComponent as MenuUp } from './Menu-up.svg';
import { ReactComponent as Minus } from './Minus.svg';
import { ReactComponent as Placeholder } from './Placeholder.svg';
import { ReactComponent as Plus } from './Plus.svg';
import { ReactComponent as PriceDown } from './Price-down.svg';
import { ReactComponent as PriceUp } from './Price-up.svg';
import { ReactComponent as Refresh } from './Refresh.svg';
import { ReactComponent as Reset } from './Reset.svg';
import { ReactComponent as RowSingle } from './Row-single.svg';
import { ReactComponent as Save } from './Save.svg';
import { ReactComponent as ScreenCollapse } from './Screen-collapse.svg';
import { ReactComponent as ScreenExpand } from './Screen-expand.svg';
import { ReactComponent as Send } from './Send.svg';
import { ReactComponent as Show } from './Show.svg';
import { ReactComponent as SortDown } from './Sort-down.svg';
import { ReactComponent as SortUp } from './Sort-up.svg';
import { ReactComponent as SwitchOff } from './Switch-off.svg';
import { ReactComponent as SwitchOn } from './Switch-on.svg';
import { ReactComponent as GreaterEqual } from './Than-greater-equal.svg';
import { ReactComponent as Greater } from './Than-greater.svg';
import { ReactComponent as LessEqual } from './Than-less-equal.svg';
import { ReactComponent as Less } from './Than-less.svg';
import { ReactComponent as User } from './User.svg';
import { ReactComponent as Wallet } from './Wallet.svg';
import { ReactComponent as Window } from './Window.svg';
import { ReactComponent as Zoom } from './Zoom.svg';

const SVG = {
  Add,
  Alert,
  AlertCircle,
  AlertInfo,
  AlertOctagon,
  AlertTriangle,
  Avatar,
  Bank,
  Bell,
  CalendarRange,
  Calendar,
  Cancel,
  Check,
  CheckCircle,
  CheckboxMix,
  CheckboxOff,
  CheckboxOn,
  CheckboxSelected,
  ChevronLeft,
  ChevronRight,
  Clock,
  CloseCircle,
  Close,
  Cog,
  Collapse,
  Delete,
  DownloadCloud,
  Download,
  Edit,
  EqualNot,
  Equal,
  Expand,
  Favorite,
  Filter,
  FilterClear,
  Grid,
  Hamburger,
  Hide,
  History,
  Keyboard,
  LockClosed,
  LockOpen,
  Mail,
  MenuDown,
  MenuLeft,
  MenuRight,
  MenuUp,
  Minus,
  Placeholder,
  Plus,
  PriceDown,
  PriceUp,
  Refresh,
  Reset,
  RowSingle,
  Save,
  ScreenCollapse,
  ScreenExpand,
  Send,
  Show,
  SortDown,
  SortUp,
  SwitchOff,
  SwitchOn,
  GreaterEqual,
  Greater,
  LessEqual,
  Less,
  User,
  Wallet,
  Window,
  Zoom,
};

export type SVGProps = React.SVGProps<SVGSVGElement> & { title?: string | undefined; };
export type SVGType = typeof SVG;

export default SVG;
