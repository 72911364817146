import * as React from 'react';
import clsx from 'clsx';
import ControlDrawer from '../../ControlLayout/ControlDrawer';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { SheetDrawerProps } from '../../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      height: '100%',
      overflow: 'hidden',
      position: 'absolute',
      right: 0,
      width: 300,
    },
    innerContainer: {
      borderLeft: `1px solid ${palette.border.main}`,
      marginLeft: 5,
      overflow: 'auto',
      padding: '14px 24px 20px 24px',
      transition: '.25s ease all',
      width: '100%',
    },
    hiddenInnerContainer: {
      boxShadow: 'none',
      transform: 'translateX(100%)',
    },
    hiddenRoot: {
      pointerEvents: 'none',
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxSheetDrawer' });

function SheetDrawer(props: SheetDrawerProps) {
  const { classes: inheritedClasses = {}, children, visible, buttons } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        inheritedClasses.root,
        !visible && classes.hiddenRoot
      )}
    >
      <ControlDrawer
        classes={{
          root: clsx(
            classes.innerContainer,
            inheritedClasses.innerContainer,
            !visible && classes.hiddenInnerContainer
          )
        }}
        buttons={buttons}
        title={props.title}
      >
        {children}
      </ControlDrawer>
    </div>
  );
}

export default SheetDrawer;
