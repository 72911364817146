import * as React from 'react';
import clsx from 'clsx';

import { 
  BuySellBalancesProps,
  BuySellBalancesStyleProps,
  BuySellBalancesClassKey
} from '../../types';
import { 
  defaultLightTheme as defaultTheme, 
  makeStyles 
} from '../../themes';

const BAR_HEIGHT_LENGTH_RATIO = 10;
const BAR_LENGTH = 100;
const BAR_HEIGHT = BAR_LENGTH / BAR_HEIGHT_LENGTH_RATIO; 

const useStyles = makeStyles<BuySellBalancesStyleProps, BuySellBalancesClassKey>(({ palette }) => ({
  root: {
    width: '80px'
  },
  bar: {
    height: `${BAR_HEIGHT}px`,
    fillOpacity: ({ disabled }) => disabled ? 0.3 : 1,
    transition: 'width .4s linear, x .4s linear',
  },
  buy: {
    fill: palette.buy.main,
  },
  container: {
    display: 'block',
  },
  empty: {
    fill: 'none',
    stroke: palette.borderDivider.main,
    strokeWidth: '1px',
  },
  sell: {
    fill: palette.sell.main,
  },
}), { defaultTheme, name: 'OnxBuySellBalances' });

const getClampedRate = (rate: number) => Math.max(0, rate);
const getNormalizedRate = (rate: number, total: number) => {
  if (total === 0) return 0;

  return (rate * 100) / total;
}

const getNormalizedRates = (buyRate: number, sellRate: number) => {
  const clampedBuyRate = getClampedRate(buyRate) || 0;
  const clampedSellRate = getClampedRate(sellRate) || 0;
  const total = clampedBuyRate + clampedSellRate;

  return {
    buyRate: getNormalizedRate(clampedBuyRate, total),
    sellRate: getNormalizedRate(clampedSellRate, total),
  }
}

function BuySellBalances({
  className,
  disabled = false,
  testId,
  ...props
}: BuySellBalancesProps) {
  const { buyRate, sellRate } = getNormalizedRates(props.buyRate, props.sellRate);
  const classes = useStyles({ disabled });

  return (
    <div 
      className={clsx(classes.root, className)}
      data-component="onx-buy-sell-balances"
      data-test-id={testId}
    >
      <svg 
        viewBox={`0 0 ${BAR_LENGTH} ${BAR_HEIGHT}`}
        xmlns="http://www.w3.org/2000/svg"
        version='1.1'
        className={classes.container}
      >
        <rect width='100%' className={clsx(classes.bar, classes.empty)}/>
        <rect width={`${buyRate}%`} className={clsx(classes.bar, classes.buy)} />
        <rect width={`${sellRate}%`} x={`${buyRate}%`} className={clsx(classes.bar, classes.sell)} />
      </svg>
    </div>
  );
}

export default BuySellBalances;
