import * as React from 'react';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { Theme, ThemeProviderProps } from '../types';

export type ClassNameGenerator = ReturnType<typeof createGenerateClassName>;

export const createClassNameGenerator = (): ClassNameGenerator  => {
  let counter = 0;
  const nextId = () => counter++;
  const defaultGenerator = createGenerateClassName();
  return (rule, styleSheet) => {
    const prefix = styleSheet?.options?.classNamePrefix || 'Onx';
    if (prefix.startsWith('Mui')) return defaultGenerator(rule, styleSheet);
    return `${prefix}-${rule.key}-${nextId()}`;
  }
}

export const generateClassName = createClassNameGenerator();

export function ThemeProvider<T = Theme>({
  children,
  scoped = true,
  theme,
  noCssBaseline = false,
  noStylesProvider = false,
}: ThemeProviderProps<T>): React.ReactElement<ThemeProviderProps<T>> {
  const content = (
    <MuiThemeProvider theme={theme}>
      {noCssBaseline ? (
        children
      ) : scoped ? (
        <ScopedCssBaseline>
          {children}
        </ScopedCssBaseline>
      ) : (
        <>
          <CssBaseline />
          {children}
        </>
      )}
    </MuiThemeProvider>
  );

  return noStylesProvider ? content : (
    <StylesProvider generateClassName={generateClassName}>{content}</StylesProvider>
  )
}
