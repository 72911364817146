import * as React from 'react';
import { DialogTitle } from '@material-ui/core';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { DialogHeaderProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  
  return {
    root: {
      alignItems: 'center',
      backgroundColor: palette.bkg2.main,
      borderBottom: `1px solid ${palette.borderTrans.main}`,
      display: 'flex',
      height: '47px',
      margin: 0,
      padding: '0 20px',

      '& .MuiTypography-h6': {
        ...fonts.displayFonts.capsMedium,
        alignItems: 'center',
        color: palette.level1.main,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }
    }
  }
}, { name: 'OnxDialogHeader', defaultTheme: defaultLightTheme})

const DialogHeader = React.forwardRef<HTMLDivElement, DialogHeaderProps>(function DialogHeader ({
  children,
  className,
  testId
}, ref) {

  const classes = useStyles();

  return (
    <DialogTitle
      className={className}
      classes={{
        root: classes.root,
      }}
      data-component="onx-dialog-header"
      data-test-id={testId}
      ref={ref}>
      {children}
    </DialogTitle>
  )
})

export default DialogHeader;
