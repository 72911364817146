import * as React from 'react';
import clsx from 'clsx';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { DialogMessageProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    message: {
      alignItems: 'stretch',
      display: 'flex',
      margin: '6px 0',
      width: '100%'
    },
    indicator: {
      backgroundColor: palette.system3.main,
      marginRight: '8px',
      minWidth: '6px',
    },
    text: {
      ...fonts.displayFonts.xSmallPara,
      color: palette.level2.main,
      fontFamily: fonts.fontFamily,
    }
  }
}, { name: 'OnxDialogMessage', defaultTheme: defaultLightTheme });

function DialogMessage ({
  children,
  classes,
  className,
  ...props
}: DialogMessageProps) {
  const cls = useStyles();

  return (
    <div className={clsx(cls.message, classes?.message, className)} {...props}>
      <div className={clsx(cls.indicator, classes?.indicator)}></div>
      <div className={clsx(cls.text, classes?.text)}>
        { children }
      </div>
    </div>
  );
}

export default DialogMessage;
