import * as React from 'react';
import { DialogContent as MuiDialogContent } from '@material-ui/core';
import { defaultLightTheme, makeStyles } from '../../themes';
import { DialogContentProps } from '../../types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 19px',
  }
}, { name: 'OnxDialogContent', defaultTheme: defaultLightTheme});

const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(function DialogContent ({
  children,
  className,
  testId
}, ref) {

  const classes = useStyles();

  return (
    <MuiDialogContent
      className={className}
      classes={{
        root: classes.root,
      }}
      data-component="onx-dialog-content"
      date-test-id={testId}
      ref={ref}>
      {children}
    </MuiDialogContent>
  )
})

export default DialogContent;
