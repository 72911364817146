import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, withStyles } from '../../themes';
import { opacities } from '../../constants';
import { Theme } from '../../types/augmented';
import { makeStyles } from '../../themes';
import { CircularProgressPropsCustom } from '../../types/circularprogress';

type CircularProgressStyleProps = Pick<CircularProgressPropsCustom, 'side' | 'status'>;

const circularProgressStyles = (theme: Theme) => createStyles<'circleDisableShrink' | 'circle' | 'determinate', CircularProgressStyleProps>({
  circleDisableShrink: {
    color: (props) => {
      switch (props.status) {
        case 'active':
          return props.side === 'buy' ? theme.palette.buy.main : theme.palette.sell.main;
        case 'completed':
          return theme.palette.affirm.main + opacities[90];
        case 'rejected':
        case 'canceled':
          return theme.palette.alert1.main + opacities[90];
        default:
          return undefined;
      }
    },
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  determinate: {
    position: 'absolute',
    right: 0,
    color: theme.palette.buy.main,
  },
});

const CustomCircularProgress = withStyles(circularProgressStyles, { name: 'OnxCircularProgress' })(CircularProgress);
// const CustomCircularProgress = CircularProgress;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: 'relative',
  },
  circle: {
    strokeLinecap: 'round',
  },
  determinate: {
    position: 'absolute',
    right: 0,
    color: palette.buy.main,
  },
}), { name: 'OnxCircularProgressCard' });

export const CircularProgressCard = React.forwardRef<HTMLDivElement, CircularProgressPropsCustom>(function CircularProgessCard({ value, ...rest }, ref) {
  const cls = useStyles();

  return (
    <div className={cls.root} ref={ref}>
      <CustomCircularProgress
        variant="determinate"
        size={40}
        thickness={6}
        value={100}
        classes={{
          circle: cls.circle,
          determinate: cls.determinate,
        }}
        {...rest}
      />
      <CircularProgress
        variant="determinate"
        size={40}
        thickness={6}
        value={value}
        classes={{
          circle: cls.circle,
          determinate: cls.determinate,
        }}
        {...rest}
      />
    </div>
  );
})

export default CircularProgressCard;