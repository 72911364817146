import * as React from 'react';
import clsx from 'clsx';
import {
  Switch as MaterialSwitch,
  FormControlLabel,
} from '@material-ui/core';
import {
  SwitchOffIcon,
  SwitchOnIcon
} from '../Icon';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { SwitchProps } from '../../types';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    switch: {
      height: '20px',
      padding: 0,
      width: '20px',
      '& .MuiSwitch-switchBase': {
        padding: 0,
        '&.Mui-checked': {
          transform: `translateX(0px)`,
          '& + .MuiSwitch-track': {
            backgroundColor: 'transparent',
          },
          '& + .MuiSwitch-thumb': {
            backgroundColor: 'transparent',
          },
        }
      },
      '& .MuiSwitch-track': {
        backgroundColor: 'transparent',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'transparent',
      },
      '& .MuiIconButton-root:hover' : {
        backgroundColor: 'transparent',
       },
    },
    single: {
      padding: '0 !important',
      '&:hover': {
        backgroundColor: 'transparent !important',
        '& .Mui-checked': {
          '& .MuiSvgIcon-root': {
            color: `${palette.level1.main} !important`,
          },
        },
      }
    },
    formControl: {
      borderRadius: '2px',
      marginLeft: 0,
      padding: '2px 4px 2px 4px',
      '&:hover': {
        backgroundColor: palette.hov1.main,
        '& .MuiFormControlLabel-label': {
          color: palette.level2.main,
        },
        '& .MuiSvgIcon-root': {
          color: palette.level3.main,
        },
      },
      '& .MuiFormControlLabel-label': {
        color: palette.level3.main,
        ...fonts.displayFonts.medium,
      },
      '& .MuiSvgIcon-root': {
        color: palette.level4.main,
      },
    },
    formControlMedium: {
      '& .MuiFormControlLabel-label': {
        ...fonts.displayFonts.small,
      },
      '& $switch': {
        height: '17px',
        width: '17px',
      }
    },
    formControlChecked: {
      '&:hover': {
        '& .MuiFormControlLabel-label': {
          color: palette.level1.main,
        },
        '& .MuiSvgIcon-root': {
          color: palette.level2.main,
        },
      },
      '& .MuiFormControlLabel-label': {
        color: palette.level1.main,
      },
      '& .MuiSvgIcon-root': {
        color: palette.level2.main,
      },
    },
    labelSpacing: {
      '& .MuiFormControlLabel-label': {
        margin: '0 4px',
      },
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxSwitch' });

function Switch({
  className,
  label = '',
  size = 'medium',
  testId,
  variant,
  classes,
  disabled,
  ...props
}: SwitchProps) {
  const cls = useStyles();

  const iconSize = size === 'large' ? 'medium' : 'small';

  return (
    <FormControlLabel
      {...props}
      control={
        <MaterialSwitch
          disabled={disabled}
          classes={{
            root: clsx(cls.switch, classes?.switch)
          }}
          icon={<SwitchOffIcon size={iconSize} />}
          checkedIcon={<SwitchOnIcon size={iconSize} />}
        />
      }
      classes={{
        root: clsx(
          cls.formControl, classes?.formControl,
          size === 'medium' && [cls.formControlMedium, classes?.formControlMedium],
          label !== '' && [cls.labelSpacing, classes?.labelSpacing],
          variant === 'single' && [cls.single, classes?.single],
          props.checked && [cls.formControlChecked, classes?.formControlChecked],
          className
        )
      }}
      data-component="onx-switch"
      data-test-id={testId}
      label={variant === 'single' ? '' : label}
    />
  );
}

export default Switch;
