import {
  SimplePaletteColorOptions,
} from '@material-ui/core/styles';
import {
  DerivedColorConfig,
  PaletteOptions,
} from '../types';
import { opacities } from '../constants';

export type RGB = {
  r: number
  g: number
  b: number
  a?: number
};

// valid hex color strings consist of 3, 4, 6, or 8 hex digits after the leading '#'
export const validColorHex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{4}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/;

export function normalizeHex(hex: string) {
  if (!validColorHex.test(hex)) return hex;
  const h = hex.replace('#', '').substring(0, 8);
  if (h.length === 3) return `#${h[0]}${h[0]}${h[1]}${h[1]}${h[2]}${h[2]}`;
  else if (h.length === 4) return `#${h[0]}${h[0]}${h[1]}${h[1]}${h[2]}${h[2]}${h[3]}${h[3]}`;
  else return `#${h}`;
}

export function hexToRGB(hex: string) {
  hex = normalizeHex(hex);
  const rgb: RGB = {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16),
  };
  if (hex.length > 7) rgb.a = parseInt(hex.slice(7, 9), 16);
  return rgb;
}

export function getRGB(str: string) {
  if (str.startsWith('#')) return hexToRGB(str);
  const [r, g, b, a] = str.slice(str.indexOf('(') + 1, str.lastIndexOf(')')).split(',');
  const rgb: RGB = {
    r: Number(r),
    g: Number(g),
    b: Number(b),
  };
  if (a) rgb.a = Math.min(255, Math.round(Number(a) * 255));
  return rgb;
}

export function normalizeRGB(rgb: RGB) {
  const normalized: RGB = {
    r: rgb.r / 255,
    g: rgb.g / 255,
    b: rgb.b / 255,
  };
  if (rgb.a) normalized.a = rgb.a / 255;
  return normalized;
}

export function denormalizeRGB(rgb: RGB) {
  const denormalized: RGB = {
    r: Math.min(255, Math.round(rgb.r * 255)),
    g: Math.min(255, Math.round(rgb.g * 255)),
    b: Math.min(255, Math.round(rgb.b * 255)),
  }
  if (rgb.a) denormalized.a = Math.min(255, Math.round(rgb.r * 255));
  return denormalized;
}

export function composeColors(background: RGB, foreground: RGB) {
  const bNorm = normalizeRGB(background);
  const fNorm = normalizeRGB(foreground);
  if (fNorm.a === 0) return background;
  if (!fNorm.a) return foreground;
  const composedNormalized = {
    r: ((1 - fNorm.a) * bNorm.r) + (fNorm.a * fNorm.r),
    g: ((1 - fNorm.a) * bNorm.g) + (fNorm.a * fNorm.g),
    b: ((1 - fNorm.a) * bNorm.b) + (fNorm.a * fNorm.b),
  };

  return denormalizeRGB(composedNormalized);
}

export function computeDerivedColor(config: DerivedColorConfig, paletteOptions: Partial<PaletteOptions>) {
  const { backgroundColor, baseColor, opacity } = config;
  const baseColorWithOpacity = `${(paletteOptions[baseColor] as SimplePaletteColorOptions).main.slice(0, 7)}${opacity ? opacities[opacity] : ''}`;
  if (!backgroundColor) return baseColorWithOpacity;
  const { r, g, b } = composeColors(
    getRGB((paletteOptions[backgroundColor] as SimplePaletteColorOptions).main),
    getRGB(baseColorWithOpacity),
  );
  return `rgb(${r},${g},${b})`;
}
