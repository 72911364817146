import * as React from 'react';
import {
  useMemo
} from 'react';
import clsx from 'clsx';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import { fonts } from '../../constants';
import {
  defaultLightTheme,
  makeStyles
} from '../../themes';
import {
  DateTimePickerToolbarProps,
  Meridiem
} from '../../types';

const useStyles = makeStyles(({ palette })=> {
  return {
    toolbar: {
      backgroundColor: 'transparent',
      height: '24px',
      justifyContent: 'flex-end',
      marginBottom: '15px',
      minHeight: '24px',
      padding: '0 0 13px 0',

      '&::after': {
        backgroundColor: palette.borderSolid.main,
        content: '" "',
        height: '1px',
        left: '-16px',
        position: 'absolute',
        top: '37px',
        width: '290px'
      },
    },
    toolbarButton: {
      '& .MuiPickersToolbarText-toolbarTxt': {
        ...fonts.displayFonts.large,
        color: palette.level3.main,
        '&.MuiPickersToolbarText-toolbarBtnSelected': {
          color: palette.level1.main,
        },
      },
      '&:hover': {
        background: 'transparent',
      },
    },
    timeSeparator: {
      ...fonts.displayFonts.large,
      color: palette.level3.main,
      margin: '0 4px',
    },
    ampm: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: fonts.fontFamily,
      marginLeft: '4px',
    },
    ampmButton: {
      ...fonts.displayFonts.xxSmall,
      color: palette.level3.main,
      cursor: 'pointer',
    },
    ampmButtonSelected: {
      color: palette.level1.main,
    },
  }
}, { name: 'OnxTimePicker', defaultTheme: defaultLightTheme });

const DateTimePickerToolbar = function({
  className,
  date,
  isLandscape,
  onChange,
  openView,
  setOpenView
}: DateTimePickerToolbarProps) {

  const meridiem = useMemo(() => {
    const _date = date ?? new Date();
    return _date.getHours() < 12 ? Meridiem.AM : Meridiem.PM;
  }, [date])

  const classes = useStyles();

  const toggleMeridiem = () => {
    if (!date) return;

    const _date = new Date(date.getTime());
    _date.setHours(_date.getHours() + (meridiem === Meridiem.AM ? 12 : -12))
    onChange(_date);
  }

  const getDisplayValue = (value: number) => {
    if (value < 10) {
      return `0${value.toString()}`;
    }

    return value.toString();
  }

  return date
    ? <PickerToolbar
      className={clsx(
        classes.toolbar,
        className
      )}
      isLandscape={isLandscape}
    >
      <ToolbarButton
        className={classes.toolbarButton}
        onClick={() => setOpenView('hours')}
        variant="h6"
        label={date.getHours() > 12
          ? getDisplayValue(date.getHours() - 12)
          : getDisplayValue(date.getHours())
        }
        selected={openView === 'hours'}
      />
      <div className={classes.timeSeparator}>:</div>
      <ToolbarButton
        className={classes.toolbarButton}
        onClick={() => setOpenView('minutes')}
        variant="h6"
        selected={openView === 'minutes'}
        label={getDisplayValue(date.getMinutes())}
      />
      <div className={classes.ampm}>
        <span
          className={clsx(
            classes.ampmButton,
            meridiem === Meridiem.AM && classes.ampmButtonSelected
          )}
          onClick={() => meridiem === Meridiem.PM && toggleMeridiem()}
        >
          am
        </span>
        <span
          className={clsx(
            classes.ampmButton,
            meridiem === Meridiem.PM && classes.ampmButtonSelected
          )}
          onClick={() => meridiem === Meridiem.AM && toggleMeridiem()}
        >
          pm
        </span>
      </div>
    </PickerToolbar>
  : null
};

export default DateTimePickerToolbar;
