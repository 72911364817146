import React from 'react';
import clsx from 'clsx';
import { SheetPanelBaseProps } from '../../../types';
import { defaultLightTheme, makeStyles } from '../../../themes';

const useStyles = makeStyles((theme) => ({
  children: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    padding: '10px 10px 0px 10px',
    border: `solid 1px ${theme.palette.border.main}`,
    alignItems: 'left',
  },
}), { defaultTheme: defaultLightTheme, name: 'OnxPanel' });

const Panel = ({
  children,
  className,
  classes: inheritedClasses = {},
  testId,
  ...props
}: SheetPanelBaseProps) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.children,
        inheritedClasses.root,
        className
      )}
      data-component="onx-panel"
      data-test-id={testId}>
      {children}
    </div>
  );
};

export default Panel;
