import * as React from 'react';
import clsx from 'clsx';
import { defaultLightTheme, makeStyles } from '../../../themes';
import {
  MenuItem as MuiMenuItem
} from '@material-ui/core';
import { fonts } from '../../../constants';
import { MenuItemProps } from '../../../types';
import { useCallback } from 'react';

const useStyles = makeStyles(({ palette }) => {
  return {
    menuItem: {
      ...fonts.displayFonts.medium,
      color: palette.level1.main,
      display: 'flex',
      height: '32px',
      justifyContent: 'space-between',
      padding: '6px 16px',
      whiteSpace: 'nowrap',
      '&.MuiListItem-button': {
        '&:hover': {
          backgroundColor: palette.hov1.main,
        },
      },
      '& .MuiFormControlLabel-root': {
        margin: 0,
      },
    },
    selected: {
      backgroundColor: palette.hov1.main,
    },
    divider: {
      backgroundColor: palette.border.main,
      height: '1px',
      margin: '6px 0',
      width: '100%',
    }
  };
}, { name: 'OnxMenuItem', defaultTheme: defaultLightTheme})

const MenuItem = React.forwardRef<HTMLLIElement, MenuItemProps>(function MenuItem({
  children,
  className,
  onClick,
  testId,
  value,
  ...rest
}, ref) {
  const classes = useStyles();

  const handleClick = useCallback(() => onClick?.(value), [onClick, value]);

  return (
    <MuiMenuItem
      classes={{
        root: clsx(
          classes.menuItem,
          className
        )
      }}
      data-component="onx-menu-item"
      data-test-id={testId}
      onClick={handleClick}
      ref={ref}
      {...rest}>
      {children}
    </MuiMenuItem>
  )
});

export default MenuItem;
