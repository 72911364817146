import React from 'react';
import { makeStyles, defaultLightTheme } from '../../themes';
import { SectionControllerProps } from '../../types';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: palette.canvas.main,
    padding: '16px',
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
    borderLeft: `1px solid ${palette.border.main}`,
    borderRight: `1px solid ${palette.border.main}`,
    '& > *': {
      margin: '0 16px 0 0',
    }
  },
}), { defaultTheme: defaultLightTheme, name: 'OnxSectionController' });

const SectionController: React.FC<SectionControllerProps> = ({
  showDateRangeControllers = true,
  onStartDateChange = () => {},
  onEndDateChange = () => {},
  controllers = null
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* {showDateRangeControllers && controllers === null && (
        <>
          <Input
            label="Start date"
            type="date"
            onChange={onStartDateChange}
          />
          <Input
            label="End date"
            type="date"
            onChange={onEndDateChange}
          />
        </>
      )} */}
      {controllers !== null && (
        controllers
      )}
    </div>
  );
};

export default SectionController;
