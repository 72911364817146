import * as React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import merge from 'deepmerge';
import {
  baseColorKeys,
  colors as defaultColors,
  derivedColorKeys,
  fonts as defaultFonts,
  opacities,
} from '../constants';
import {
  ColorOverrides,
  ComponentCreator,
  CustomThemeOptions,
  DerivedColorConfig,
  Fonts,
  PaletteModeConfig,
  PaletteOptions,
  Theme,
  ThemeOptions,
} from '../types';
import {
  ClassNameMap,
  Styles,
  WithStylesOptions,
} from '@material-ui/core/styles/withStyles';
import {
  createStyles as muiCreateStyles,
  makeStyles as muiMakeStyles,
  styled as muiStyled,
  withStyles as muiWithStyles,
  withTheme as muiWithTheme,
  useTheme as muiUseTheme,
  WithTheme,
  StyleRules,
} from '@material-ui/core/styles';
import { ConsistentWith } from '@material-ui/types';
import { computeDerivedColor } from '../utils';

function getPaletteOptionsBase(
  type: 'dark' | 'light',
  colors: PaletteModeConfig,
  overrides: ColorOverrides
) {
  const paletteOptions: Partial<PaletteOptions> = { type };
  for (const key of baseColorKeys) {
    paletteOptions[key] = {
      main: overrides[key] || (colors[key] as NonNullable<React.CSSProperties['color']>),
    };
  }
  for (const key of derivedColorKeys) {
    paletteOptions[key] = {
      main: overrides[key] || computeDerivedColor(colors[key] as DerivedColorConfig, paletteOptions)
    };
  }
  paletteOptions.primary = {
    main: (paletteOptions as PaletteOptions).buy.main,
  };
  paletteOptions.secondary = {
    main: (paletteOptions as PaletteOptions).sell.main,
  };
  paletteOptions.info = {
    main: (paletteOptions as PaletteOptions).system1.main,
  };
  paletteOptions.background = {
    default: paletteOptions.page?.main,
    paper: paletteOptions.canvas?.main,
  };
  paletteOptions.text = {
    primary: paletteOptions.level1?.main,
    secondary: paletteOptions.level2?.main,
    disabled: paletteOptions.level3?.main,
  };
  paletteOptions.divider = paletteOptions.borderSolid?.main;
  return paletteOptions as PaletteOptions;
}

function getThemeOptionsBase(
  type: 'dark' | 'light',
  colors: PaletteModeConfig,
  colorOverrides: ColorOverrides,
  fonts: Fonts
): ThemeOptions {
  const isDark = type === 'dark';

  const palette = getPaletteOptionsBase(type, colors, colorOverrides);

  return {
    palette,
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    typography: { fontFamily: fonts.fontFamily },
    overrides: {
      MuiFormControl: {
        root: {
          '&.has-value .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.level4.main,
          },
        },
        marginNormal: {
          marginTop: '0px',
        },
      },
      MuiOutlinedInput: {
        root: {
          padding: '12px 12px 12px 16px',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
        },
        input: {
          height: 'auto',
          padding: 0,
        },
        notchedOutline: {
          borderColor: palette.level4.main,
        },
        inputMarginDense: {
          paddingTop: '8.5px',
          paddingBottom: '8.5px',
        },
      },
      MuiInputLabel: {
        root: {
          color: palette.level3.main,
          '&$focused': {
            color: palette.system2.main,
          },
          '&$error': {
            color: palette.alert1.main,
          },
          '&$disabled': {
            color: palette.level4.main,
          },
          '&$outlined': {
            transform: 'translate(14px, 15px) scale(1)',
          },
        },
      },
      MuiTextField: {
        root: {
          width: '316px',
          borderRadius: '4px',
        },
      },
      MuiInputBase: {
        input: {
          boxSizing: 'inherit',
          height: '44px',
          left: '12px',
          top: '22px',
          color: palette.level1.main,
          padding: '8px 0 7px',
          ...fonts.displayFonts.medium,
          '&.MuiInputBase-input:disabled':{
            color: palette.level4.main,
          },
        },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: palette.level3.main,
          '&focus': {
            color: palette.level2.main,
          },
        },
      },
      MuiDialogTitle: {
        root: {
          ...fonts.displayFonts.xLargeStrong,
          color: palette.level1.main,
          padding: '0px',
          marginBottom: '24px',
        },
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: palette.bkg3.main,
          },
          '&:active': {
            backgroundColor: `${palette.ink.main}${opacities[80]}`,
          },
          '&.Mui-selected': {
            backgroundColor: `${palette.ink.main}${opacities[10]}`,
          },
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '15px',
        },
      },
      MuiLink: {
        root: {
          color: palette.alert2.main,
        },
      },
      MuiMenu: {
        list: {
          backgroundColor: palette.canvas.main,
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: '8px',
        },
      },
      MuiCardContent: {
        root: {
          padding: 0,
        },
      },
      MuiPickersDay: {
        day: {
          color: `${palette.ink.main}${opacities[80]}`,
          height: '24px',
          width: '24px',
        },
        daySelected: {
          color: `${palette.ink.main}${opacities[100]}`,
          backgroundColor: `${palette.canvas.main}${opacities[100]}`,
          border: `1px solid ${palette.ink.main}${opacities[60]}`,
          '&:hover': {
            backgroundColor: `${palette.canvas.main}${opacities[100]}`,
          },
        },
        hidden: {
          opacity: 1,
          color: `${palette.ink.main}${opacities[20]}`,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          marginBottom: '6px',
        },
        dayLabel: {
          width: '24px',
        },
        iconButton: {
          backgroundColor: 'none',
        },
        daysHeader: {
          justifyContent: 'space-between',
        }
      },
      MuiPickersBasePicker: {
        container: {
          border: `1px solid ${palette.ink.main}${opacities[30]}`,
          borderRadius: '4px',
          padding: '0px 18px 16px 8px',
          width: '252px',
        },
        pickerView: {
          minWidth: '0px',
          minHeight: 'auto',
        },
      },
      MuiPickersCalendar: {
        week: {
          margin: '4px 0px !important',
          justifyContent: 'space-between',
        },
        transitionContainer: {
          minHeight: '161px',
          marginTop: '0px',
        },
      },
      MuiPickersSlideTransition: {
        transitionContainer: {
          color: `${palette.ink.main}${opacities[80]}`,
        },
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          backgroundColor: 'none',
        },
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: palette.canvas.main,
          },
        },
      },
      MuiScopedCssBaseline: {
        root: {
          backgroundColor: palette.canvas.main,
        },
      },
    },
  };
}

function getThemeOptions({
  type = 'light',
  overrides = {},
}: CustomThemeOptions): ThemeOptions {
  const isDark = type === 'dark';
  const baseColors = isDark ? defaultColors.dark : defaultColors.light;
  const mergedFonts = overrides.fonts
    ? merge<Fonts>(defaultFonts, overrides.fonts)
    : { ...defaultFonts }; // shallow clone ok
  return getThemeOptionsBase(
    type,
    baseColors,
    overrides.colors ?? {},
    mergedFonts
  );
}

export function createStyles<
  ClassKey extends string,
  Props extends {}
>(styles: StyleRules<ClassKey, Props>): StyleRules<ClassKey, Props> {
  return muiCreateStyles<ClassKey, Props>(styles);
}

export function createTheme(
  options?: CustomThemeOptions,
  ...args: object[]
): Theme {
  return createMuiTheme(getThemeOptions(options ?? {}), ...args);
}

/** Here we re-export mui style solutions */

export function makeStyles<
  Props extends object = {},
  ClassKey extends string = string
>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
): keyof Props extends never
  ? (props?: any) => ClassNameMap<ClassKey>
  : (props: Props) => ClassNameMap<ClassKey> {
  return muiMakeStyles<Theme, Props, ClassKey>(styles, options);
}

export function styled<C extends React.ElementType>(
  Component: C
): ComponentCreator<C> {
  return muiStyled(Component) as ComponentCreator<C>;
}

export function withStyles<
  ClassKey extends string,
  Options extends WithStylesOptions<Theme> = {},
  Props extends object = {}
>(style: Styles<Theme, Props, ClassKey>, options?: Options) {
  return muiWithStyles<ClassKey, Options, Props>(style, options);
}

export function withTheme<
  C extends React.ComponentType<ConsistentWith<React.ComponentProps<C>, WithTheme>>
>(component: C) {
  return muiWithTheme<C>(component);
}

export function useTheme<T = Theme>(): T {
  return muiUseTheme<T>();
}
