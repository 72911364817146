import React, { useState } from 'react';
import clsx from 'clsx';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { FiltersProps } from '../../types';
import Chip from '../Chip/Chip';
import { defaultLightTheme, makeStyles } from '../../themes';
import FiltersModal from './FiltersModal';
import FiltersDropdown from './FiltersDropdown';

const useStyles = makeStyles((theme) => {
  const { palette, typography } = theme
  return {
    main: {
      alignItems: 'center',
      backgroundColor: palette.canvas.main,
      border: '1px solid',
      borderColor: palette.level4.main,
      display: 'flex',
      flexDirection: 'row',
      fontFamily: typography.fontFamily,
      height: '48px',
      padding: '0 16px',
    },
    title: {
      color: palette.ink.main,
    },
    filters: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '8px',
      '&:last-child': {
        marginLeft: '8px',
      },
    },
    chips: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '& > *': {
        marginRight: '8px',
      },
    },
    filtersSelector: {
      marginLeft: '8px',
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxFilter' });

const variantComponent = {
  dropdown: FiltersDropdown,
  modal: FiltersModal,
};

const Filters: React.FC<FiltersProps> = ({
  className,
  onChange,
  supportedFilters = [],
  initialValue = [],
  testId,
  variant = 'dropdown',
}) => {
  const classes = useStyles();
  const [appliedFilters, setAppliedFilters] = useState(initialValue);
  const addNewFilters = (newFilterSelection: string[]) => {
    setAppliedFilters(newFilterSelection);
    onChange?.(newFilterSelection);
  }
  const removeFilter = (filterValue: string) => {
    const newFilterSelection = appliedFilters.filter(filter => filter !== filterValue);
    setAppliedFilters(newFilterSelection);
    onChange?.(newFilterSelection);
  }

  const FiltersSelectorComponent = variantComponent[variant]

  return (
    <div
      className={clsx(
        classes.main,
        className
      )}
      data-component="onx-filters"
      data-test-id={testId}
    >
      <div className={classes.filters}>
        <DragDropContext
          onDragEnd={(dropResult: DropResult) => {
            setAppliedFilters(prevAppliedFilters => {
              if (!dropResult.destination) {
                return prevAppliedFilters
              }

              const newValue = [...prevAppliedFilters]
              const [removed] = newValue.splice(dropResult.source.index, 1)
              newValue.splice(dropResult.destination.index, 0, removed)

              return newValue
            })
          }}
        >
          <Droppable droppableId="filters-droppable" direction="horizontal">
            {(provided: DroppableProvided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.chips}
              >
                {appliedFilters.map((filterId: string, index: number) => {
                  const filter = supportedFilters.find(filter => filter.value === filterId)
                  return filter && (
                    <Draggable key={filter.value} draggableId={filter.value} index={index}>
                      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            ...provided.draggableProps.style,
                          }}
                        >
                          <Chip
                            key={filter.value}
                            label={filter.name}
                            onDelete={() => removeFilter(filter.value)}
                            disabled={false}
                            active={snapshot.isDragging}
                            deleteTooltipTitle="Remove filter"
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className={classes.filtersSelector}>
          <FiltersSelectorComponent
            onChange={addNewFilters}
            options={supportedFilters}
            values={appliedFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
