import React from 'react';
import { useMemo } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import {
  ToggleProps,
  ToggleSize
} from '../../types';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const isLight = palette.type === 'light';

  const hoverOverlayColor = {
    buy: palette.hov1.main,
    sell: palette.hov1.main,
    standard: isLight
      ? palette.level4.main
      : palette.bkg1.main,
  };

  return {
    root: {
      boxShadow: 'none !important',
      borderRadius: '4px',
      height: '37px',
      minWidth: '90px',
      padding: '9px 16px',
      border: '1px solid transparent',
      '&::after': {
        borderRadius: '4px',
        content: "''",
        position: 'absolute',
        top: -1,
        left: -1,
        right: -1,
        bottom: -1,
      },
      '&$disabled': {
        backgroundColor: `${palette.level4.main} !important`,
      },
    },
    label: {
      ...fonts.displayFonts.capsMedium,
      zIndex: 1,
      color: isLight
        ? palette.canvas.main
        : palette.ink.main,
    },
    sizeLarge: {
      height: '44px',
      padding: '11px 20px',
      minWidth: '100px',
      '& .MuiButton-label': {
        ...fonts.displayFonts.capsLarge,
      },
    },
    sizeSmall: {
      height: '24px',
      minWidth: '50px',
      padding: '4px 8px',
      '& .MuiButton-label': {
        ...fonts.displayFonts.capsSmall,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      borderRadius: 0,
      backgroundColor: palette.canvas.main,
      border: `1px solid ${palette.borderTrans.main}`,
      flex: 1,
      overflow: 'hidden',
      '&::after': {
        borderRadius: 0,
      },
      '&:hover': {
        backgroundColor: palette.hov1.main,
        opacity: 1,
        ...isLight && ({
          '&::after': {
            backgroundColor: hoverOverlayColor.buy,
          },
        }),
        '& .MuiButton-label': {
          color: palette.level1.main,
        },
      },
      '&:not(:first-child):not(:last-child)': {
        borderLeft: 'none',
        borderRight: 'none',
      },
      '&:first-child, &:first-child::after': {
        borderRadius: '4px 0 0 4px',
        borderRight: 'none',
      },
      '&:last-child, &:last-child::after': {
        borderRadius: '0 4px 4px 0',
        borderLeft: 'none',
      },
      '&.Mui-disabled': {
        backgroundColor: `${palette.canvas.main} !important`,
        '& .MuiButton-label': {
          color: palette.level3.main,
        },
      },
      '& .MuiButton-label': {
        color: palette.level3.main,
      },
      '& span': {
        zIndex: 1,
      },
    },
    buttonSelected: {
      borderColor: palette.borderTrans.main,
      position: 'relative',
      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.chalk.main,
        },
      },
      '& .MuiButton-label': {
        color: palette.chalk.main,
      },
      '&.Mui-disabled': {
        backgroundColor: `${palette.level4.main} !important`,
        '& .MuiButton-label': {
          color: isLight
            ? palette.canvas.main
            : palette.level4.main,
        },
      },
      '&.standard': {
        backgroundColor: palette.system1.main,
        '&:hover&::after': {
          backgroundColor: hoverOverlayColor.standard,
        },
      },
      '&.buy': {
        backgroundColor: palette.buy.main,
        '&:hover&::after': {
          backgroundColor: hoverOverlayColor.buy,
        },
      },
      '&.sell': {
        backgroundColor: palette.sell.main,
        '&:hover&::after': {
          backgroundColor: hoverOverlayColor.sell,
        },
      },
    },
  };
}, { name: 'OnxToggle', defaultTheme: defaultLightTheme });

type DividerProps = {
  className?: string,
  disabled?: boolean,
  i: number,
  selected: number,
  size?: ToggleSize,
  tabsCount: number,
  testId?: string,
  variant: 'buy' | 'sell' | 'standard' | undefined
}

const useDividerStyles = makeStyles(({ palette }) => {
  const sizes: Record<ToggleSize, number> = {
    large: 44,
    medium: 37,
    small: 24,
  };

  return {
    divider: {
      backgroundColor: palette.borderTrans.main,
      border: 'none',
      width: '1px',
      padding: 0,
      transitionProperty: 'background-color, border-color',
      transitionDuration: '.25s',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    buy: {
      backgroundColor: palette.buy.main,
    },
    sell: {
      backgroundColor: palette.sell.main,
    },
    standard: {
      backgroundColor: palette.system1.main,
    },
    selected: {
      borderTop: `1px solid ${palette.borderTrans.main}`,
      borderBottom: `1px solid ${palette.borderTrans.main}`,
    },
    disabled: {
      backgroundColor: palette.level4.main,
    },
    small: {
      height: sizes.small,
    },
    medium: {
      height: sizes.medium,
    },
    large: {
      height: sizes.large,
    },
  };
}, { name: 'OnxToggleDivider', defaultTheme: defaultLightTheme });

function Divider ({
  className,
  disabled = false,
  i,
  selected,
  size = 'medium',
  tabsCount,
  testId,
  variant = 'standard'
}: DividerProps) {
  const classes = useDividerStyles();
  const isAdjacent = i === selected - 1 || i === selected;

  return i < tabsCount - 1
    ? <div
        className={clsx(
          classes.divider,
          className,
          classes[size],
          isAdjacent && classes.selected,
          isAdjacent && classes[variant],
          disabled && classes.disabled
        )}
        data-component="onx-toggle-divider"
        data-test-id={testId}
      />
    : null;
}

function Toggle({
  ButtonProps = {},
  className,
  classes: inheritedClasses = {},
  disabled = false,
  onChange,
  selected,
  size = 'medium',
  tabs = [],
  testId,
}: ToggleProps) {

  const classes = useStyles();

  const selectedIndex = useMemo((): number => {
    return tabs.findIndex((tab, index) => selected === index || selected === tab.label || selected === tab.key);
  }, [selected, tabs]);

  return (
    <div
      className={clsx(
        classes.container,
        inheritedClasses.container,
        className
      )}
      data-component="onx-toggle"
      data-test-id={testId}
    >
      {tabs.map(({ label, variant, key }, i) => {
        return (
          <React.Fragment key={key}>
            <Button
              onClick={() => onChange(i, label, key)}
              classes={{
                root: classes.root,
                label: classes.label,
                sizeLarge: classes.sizeLarge,
                sizeSmall: classes.sizeSmall,
              }}
              className={clsx(
                classes.button,
                inheritedClasses.button,
                selectedIndex === i && [
                  classes.buttonSelected,
                  inheritedClasses.buttonSelected
                ],
                !variant ? 'standard' : variant
              )}
              disableRipple
              key={key ?? i}
              disabled={disabled}
              size={size}
              variant="contained"
              {...ButtonProps}
            >
              {label}
            </Button>

            <Divider
              disabled={disabled}
              i={i}
              selected={selectedIndex}
              size={size}
              tabsCount={tabs.length}
              variant={tabs[selectedIndex]?.variant} />
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Toggle;
