import React from 'react';
import MaterialCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '../../themes';
import { CloseIcon } from '../Icon';
import Tooltip from '../Tooltip/Tooltip';
import { CardPropsCustom } from '../../types/card';
import { opacities, fonts } from '../../constants';
import CircularProgressCard from './CircularProgress';

const useStyles = makeStyles<CardPropsCustom>((theme) => {
  const { palette } = theme;
  const isLight = palette.type === 'light';
  return {
    cardRoot: {
      background: `${palette.canvas.main}${opacities[100]}`,
      border: `1px solid ${palette.brandCanvas.main}${opacities[4]}`,
      borderRadius: '4px',
      fontFamily: fonts.fontFamily,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    root: {
      '&.MuiPaper-root:hover': {
        border: '2px solid rgba(28, 54, 93, 0.3)',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
      width: (props: CardPropsCustom) =>
        props.size === 'small'
          ? '300px'
          : props.size === 'medium'
            ? '65%'
            : '100%',
      height: '132px',
      fontFamily: theme.typography.fontFamily,
      border: '1px solid rgba(28, 54, 93, 0.3)',
      borderRadius: '8px',
      boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.24)',
      boxSizing: 'border-box'
    },
    header: {
      height: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      textTransform: 'capitalize',
      borderBottom: `1px solid ${palette.borderTrans.main}`,
      padding: '0px 10px',
      background: (props) => {
        switch (props.status) {
          case 'active':
            return props.side === 'buy' ? palette.buy.main : palette.sell.main;
          case 'completed':
            return palette.affirm.main;
          case 'rejected':
          case 'canceled':
            return palette.alert1.main;
          default:
            return undefined;
        }
      },
    },
    status: {
      ...fonts.displayFonts.capsMedium,
      marginTop: '8px',
      fontSize: '14px',
      color: palette.chalk.main
    },
    label: {
      marginTop: '8px',
      ...fonts.displayFonts.mediumStrong,
      opacity: 0.9,
      color: palette.chalk.main
    },
    content: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      textTransform: 'capitalize',
      color: palette.brandCanvas.main,
      opacity: '0.6',
      marginTop: '8px',
      padding: '0px 10px'
    },
    price: {
      fontSize: '12px',
      color: isLight
        ? palette.brandCanvas.main
        : palette.ink.main,
    },
    avgPrice: {
      ...fonts.displayFonts.xSmall,
      color:
        isLight
          ? `${palette.brandCanvas.main}${opacities[90]}`
          : `${palette.ink.main}${opacities[90]}`,
    },
    time: {
      position: 'absolute',
      ...fonts.displayFonts.capsSmall,
      top: '13px',
      right: (props) => {
        return props.time && props.time.length > 2 ? '-1px' : '2px';
      },
      color:
        isLight
          ? `${palette.brandCanvas.main}${opacities[90]}`
          : `${palette.ink.main}${opacities[90]}`,
      marginRight: '12px',
    },
    min: {
      textTransform: 'lowercase',
      position: 'absolute',
      fontSize: '10px',
      fontWeight: 500,
      top: '39px',
      right: '9px',
      color:
        isLight
          ? `${palette.brandCanvas.main}${opacities[40]}`
          : `${palette.ink.main}${opacities[50]}`,
    },
    side: {
      marginTop: '8px',
      marginRight: '6px',
      textTransform: 'uppercase',
      color: palette.chalk.main
    },
    title: {
      fontWeight: 400,
      marginBottom: '8px',
      marginTop: '10px',
      fontSize: '24px',
      color: (props: CardPropsCustom) => {
        switch (props.status) {
          case 'active':
            return props.side === 'buy' ? palette.buy.main : palette.sell.main;
          case 'completed':
            return palette.affirm.main;
          case 'rejected':
          case 'canceled':
            return palette.alert1.main;
          default:
            return undefined;
        }
      },
      padding: '0px 10px'
    },
    topRightCard: {
      position: 'absolute',
      display: 'flex',
      top: '10px',
      right: '16px'
    },
    progressColor: {
      backgroundColor: (props: CardPropsCustom) => {
        switch (props.status) {
          case 'active':
            return props.side === 'buy' ? palette.buy.main + opacities[20] : palette.sell.main + opacities[20];
          case 'completed':
            return palette.affirm.main + opacities[20];
          case 'rejected':
          case 'canceled':
            return palette.alert1.main + opacities[20];
          default:
            return undefined;
        }
      },
      '& .MuiCircularProgress-colorSecondary': {
        backgroundColor: (props: CardPropsCustom) => {
          switch (props.status) {
            case 'active':
              return props.side === 'buy' ? palette.buy.main : palette.sell.main;
            case 'completed':
              return palette.affirm.main + opacities[20];
            case 'rejected':
            case 'canceled':
              return palette.alert1.main + opacities[20];
            default:
              return undefined;
          }
        }
      }
    },
    progress: {
      fontSize: '12px',
      padding: '0px 10px',
      color:
        isLight
          ? `${palette.brandCanvas.main}${opacities[90]}`
          : `${palette.ink.main}${opacities[90]}`,
    },
    errMsg: {
      color:
        isLight
          ? `${palette.brandCanvas.main}${opacities[50]}`
          : `${palette.ink.main}${opacities[50]}`,
      fontSize: '12px',
      letterSpacing: '0.4px',
      fontWeight: 'normal',
      lineHeight: '16px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '0px 10px'
    },
    errorIcon: {
      width: '18px',
      color: `${palette.alert1.main}`,
      fontSize: '1.1rem',
      marginRight: '2px',
    },
    amount: {
      color:
        isLight
          ? `${palette.brandCanvas.main}${opacities[50]}`
          : `${palette.ink.main}${opacities[40]}`,
      display: 'inline-block',
    },
    totalAmount: {
      color:
        isLight
          ? `${palette.brandCanvas.main}${opacities[90]}`
          : `${palette.ink.main}${opacities[90]}`,
      opacity: '0.7',
      fontSize: '12px',
      fontWeight: 400,
    },
    cardCorner: {
      display: 'flex',
    },
    crossIcon: {
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px'
      },
      marginTop: '6px',
      color:
        `${palette.brandCanvas.main}${opacities[40]}`
    },
    progressBottom: {
      marginTop: '16px',
      '& .MuiLinearProgress-root': {
        height: '9px',
        marginTop: '10px'
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: (props: CardPropsCustom) => {
          switch (props.status) {
            case 'active':
              return props.side === 'buy' ? palette.buy.main : palette.sell.main;
            case 'completed':
              return palette.affirm.main + opacities[20];
            case 'rejected':
            case 'canceled':
              return palette.alert1.main + opacities[20];
            default:
              return undefined;
          }
        }
      }
    }
  };
}, { name: 'OnxOrderCard' });

function Card({
  side,
  size = 'medium',
  status,
  amount,
  totalAmount,
  titleAmount,
  currency,
  avgPrice,
  errMsg,
  time,
  statusBar,
  progress,
  onClose = () => { },
}: CardPropsCustom) {
  const classes = useStyles({ side, size, status, time });
  return (
    <MaterialCard variant="outlined" className={classes.root} classes={{root: classes.cardRoot}}>
      <CardContent>
        <div className={classes.header}>
          <div className={classes.label}>
            {status}
          </div>
          <div className={classes.cardCorner}>
            <div className={classes.status}>{side}</div>
            {status !== 'active' && <div className={classes.crossIcon}>{onClose && <CloseIcon onClick={onClose} />} </div>}
          </div>
        </div>
        <div className={classes.content}>
          {avgPrice && (
            <div><span className={classes.avgPrice}>Avg Price</span> <span className={classes.price}>{avgPrice}</span></div>
          )}

          <div style={time ? { visibility: 'visible' } : { visibility: 'hidden' }} className={classes.topRightCard}>
            <div className={classes.time}>{time}</div>
            <Tooltip placement='top' title={`${time} minutes remaining`} >
              <CircularProgressCard value={statusBar} status={status} side={side} />
            </Tooltip>
            <div className={classes.min}>mins</div>
          </div>

        </div>
        <div className={classes.title}>
          {titleAmount} {currency}
        </div>
        <div className={classes.progressBottom}>
          <div className={classes.progress}>
            {progress}{' '}
            {amount ? (
              <div className={classes.amount}>/ {amount}</div>
            ) : (
              <div className={classes.totalAmount}> {totalAmount}</div>
            )}
          </div>
          {errMsg && <div className={classes.errMsg}>{errMsg}</div>}
          <LinearProgress className={classes.progressColor} variant="determinate" value={statusBar || 100} />
        </div>
      </CardContent>
    </MaterialCard>
  );
}
export default Card;
