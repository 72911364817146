import * as React from 'react';
import GridFilterDiscreteList from './GridFilterDiscreteList';
import GridFilterNumericRange from './GridFilterNumericRange';
import GridFilterStringMatch from './GridFilterStringMatch';
import GridFilterDateRange from './GridFilterDateRange';
import { GridFilterProps } from '../../../types';

const FILTER_TYPES = ['category', 'exclusiveCategory', 'numberRange', 'stringMatch', 'dateRange'];

function GridFilter ({
  className,
  filterData,
  iconSize = 'xSmall',
  onFilter
}: GridFilterProps) {


  if (!FILTER_TYPES.includes(filterData.type)) return null;

  switch (filterData.type) {
    case 'category':
      return <GridFilterDiscreteList
        className={className}
        filterOptions={filterData.options}
        iconSize={iconSize}
        multiple={true}
        onClose={onFilter}
        value={filterData.values}
        showSelectAll={true}
      />;

    case 'exclusiveCategory':
      return <GridFilterDiscreteList
        className={className}
        filterOptions={filterData.options}
        iconSize={iconSize}
        onClose={onFilter}
        value={filterData.value}
        showClear={true}
      />;

    case 'numberRange':
      return <GridFilterNumericRange
        className={className}
        condition={filterData.condition}
        filterOptions={filterData.options}
        iconSize={iconSize}
        onClose={onFilter}
      />;

    case 'stringMatch':
      return <GridFilterStringMatch
        className={className}
        condition={filterData.condition}
        filterOptions={filterData.options}
        iconSize={iconSize}
        onClose={onFilter}
        value={filterData.value}
      />;

    case 'dateRange':
      return <GridFilterDateRange
        className={className}
        condition={filterData.condition}
        endDate={filterData.endDate}
        filterOptions={filterData.options}
        iconSize={iconSize}
        initialDate={filterData.initDate}
        onClose={onFilter}
      />;

    default:
      return null;
  }
}

export default GridFilter;
