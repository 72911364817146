import * as React from 'react';
import clsx from 'clsx';
import { Button as MaterialButton } from '@material-ui/core';
import { opacities, fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { ButtonProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  const isLight = palette.type === 'light';

  return {
    root: {
      boxShadow: 'none !important',
      borderRadius: '4px',
      height: '37px',
      minWidth: '90px',
      padding: '9px 16px',
      border: '1px solid transparent',
      '&::after': {
        borderRadius: '4px',
        content: "''",
        position: 'absolute',
        top: -1,
        left: -1,
        right: -1,
        bottom: -1,
      },
      '&$disabled': {
        backgroundColor: `${palette.level4.main} !important`,
      },
    },
    label: {
      ...fonts.displayFonts.capsMedium,
      zIndex: 1,
      color: isLight
        ? palette.canvas.main
        : palette.ink.main,
    },
    sizeLarge: {
      height: '44px',
      padding: '11px 20px',
      minWidth: '100px',
      '& .MuiButton-label': {
        ...fonts.displayFonts.capsLarge,
      },
    },
    sizeSmall: {
      height: '24px',
      minWidth: '50px',
      padding: '4px 8px',
      '& .MuiButton-label': {
        ...fonts.displayFonts.capsSmall,
      },
    },
    system: {
      backgroundColor: `${palette.system1.main} !important`,
      '&:hover': {
        '&::after': {
          background: isLight
            ? `${palette.ink.main}${opacities[30]}`
            : `${palette.ink.main}${opacities[10]}`
        },
      },
      '&.Mui-disabled': {
        backgroundColor: `${palette.level4.main} !important`,
        '& .MuiButton-label': {
          color: isLight
            ? palette.chalk.main
            : palette.level4.main,
        },
      },
    },
    sell: {
      backgroundColor: `${palette.sell.main} !important`,
      '&:hover': {
        '&::after': {
          background: `${palette.ink.main}${opacities[10]}`,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: `${palette.level4.main} !important`,
        '& .MuiButton-label': {
          color: isLight
            ? palette.chalk.main
            : palette.level4.main,
        },
      },
    },
    buy: {
      backgroundColor: `${palette.buy.main} !important`,
      '&:hover': {
        '&::after': {
          background: isLight
            ? `${palette.ink.main}${opacities[20]}`
            : `${palette.ink.main}${opacities[10]}`,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: `${palette.level4.main} !important`,
        '& .MuiButton-label': {
          color: isLight
            ? palette.chalk.main
            : palette.level4.main,
        },
      },
    },
    outline: {
      backgroundColor: `${palette.canvas.main} !important`,
      borderColor: palette.borderTrans.main,
      '& .MuiButton-label': {
        color: palette.level1.main,
      },
      '&:hover': {
        '&::after': {
          backgroundColor: palette.hov1.main,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: `${palette.canvas.main} !important`,
        borderColor: palette.borderTrans.main,
        '& .MuiButton-label': {
          color: palette.level3.main,
        },
      },
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxButton' });

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  color = 'system',
  disabled = false,
  onClick,
  size = 'medium',
  testId,
  width,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MaterialButton
      classes={{
        root: classes.root,
        label: classes.label,
        sizeLarge: classes.sizeLarge,
        sizeSmall: classes.sizeSmall,
      }}
      className={clsx(classes[color], className)}
      disableRipple
      data-component="onx-button"
      data-test-id={testId}
      disabled={disabled}
      onClick={onClick}
      size={size}
      style={{
        width,
      }}
      variant="contained"
      {...props}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
