import React from 'react';
import {
  useCallback,
  useState
} from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { CloseIcon } from '../Icon';
import { IconButton } from '../IconButton';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import {
  BrandedProps,
  ModalProps
} from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    container: {
      backgroundColor: palette.borderTrans.main,
      '& .MuiDialog-paper': {
        backgroundColor: palette.canvas.main,
        borderRadius: '8px',
        boxSizing: 'border-box',
        height: 'auto',
        minHeight: ({ isBranded }: BrandedProps) => isBranded ? '272px' : '200px',
        overflow: 'hidden',
        width: '640px',
      },
    },
    header: {
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      margin: ({ isBranded }: BrandedProps) => isBranded
          ? '0 32px 24px 120px'
          : '32px 32px 24px 120px',
    },
    brandedTopBar: {
      alignItems: 'center',
      backgroundColor: palette.brandCanvas.main,
      boxSizing: 'border-box',
      display: 'flex',
      minHeight: '72px',
      padding: '16px 32px 16px 120px',
      marginBottom: '24px',
    },
    title: {
      color: palette.ink.main,
      margin: 0,
      '& .MuiTypography-h6': {
        ...fonts.displayFonts.xLargeStrong,
      },
    },
    content: {
      boxSizing: 'border-box',
      margin: '0 41px 24px 120px',
      overflowY: 'auto',
      padding: '6px 10px 0 0',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.hov1.main,
        borderRadius: '4px',
        '&:hover': {
          background: palette.hov2.main,
        },
      },
      '&::-webkit-scrollbar-track': {
        background: palette.canvas.main,
        borderRadius: 0,
      },
    },
    footer: {
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0 32px 32px 120px',
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxModal' });

const Modal = ({
  actions,
  brandLogo,
  isBranded = false,
  children,
  className,
  onClose,
  showCloseButton = true,
  testId,
  title,
  ...props
}: ModalProps ) => {
  const [isModalFullHeight, setIsModaFullHeight] = useState<boolean>(false);
  const [applyMaxHeight, setApplyMaxHeight] = useState<boolean>(false);
  
  const maxHeight = 480;
  const modalRef = useCallback(node => {

    if (node) {
      if (node.offsetHeight > maxHeight) {
        // Check if modal overflows maxHeight
        setIsModaFullHeight(true);
      }

      const parentOffset = node.offsetParent.offsetHeight;
      if (isModalFullHeight && parentOffset > maxHeight) {
        // If we have an overflow and there's enough space on the parent to allow the modal to grow, we apply the maxHeight
        setApplyMaxHeight(true);
      } else {
        setApplyMaxHeight(false);
      }
    }

  }, [isModalFullHeight]);

  const classes = useStyles({ isBranded });

  return (
    <Dialog
      className={clsx(
        className,
        classes.container
      )}
      data-test-id={testId}
      data-component='onx-modal'
      maxWidth="xl"
      PaperProps={{
        elevation: 4,
        ref: modalRef,
        ...(isModalFullHeight && applyMaxHeight) &&
            {
              style: {
                height: maxHeight,
              }
            }
      }}
      {...props}
    >
      {
        (brandLogo && isBranded) &&
          <div className={classes.brandedTopBar}>
            <img
              alt={brandLogo.title}
              src={brandLogo.src}
            />
          </div>
      }

      <div className={classes.header}>
        <DialogTitle classes={{ root: classes.title }}>
          {title}
        </DialogTitle>

        {
          showCloseButton && 
            <IconButton
              IconComponent={CloseIcon}
              onClick={onClose}
              size="xLarge" />
        }
      </div>

      <div className={classes.content}>
        {children}
      </div>

      {
        actions &&
          <div className={classes.footer}>
            { actions }
          </div>
      }
    </Dialog>
  );
};

export default Modal;
