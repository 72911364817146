
import * as React from 'react';
import { useRef } from 'react';
import {
  Table,
  TableContainer
} from '@material-ui/core';
import useInfiniteLoader from './useInfiniteLoader';
import { defaultLightTheme, makeStyles } from '../../themes';
import { GridProps } from '../../types';

const useStyles = makeStyles(() => {
  return {
    container: {
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: ({ width }: Pick<GridProps, 'fixed' | 'width'>) => width ?? '100%',

      '& .infinite-scroll-component__outerdiv': {
        height: '100%',
      },
    },
    table: {
      borderCollapse: 'separate',
      display: 'table',
      tableLayout: ({ fixed }: Pick<GridProps, 'fixed' | 'width'>) => fixed ? 'fixed' : 'auto',
      height: 'auto',
      width: '100%',
      overflow: 'auto',
    },
    cellHeadContent: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
}, { name: 'OnxGrid', defaultTheme: defaultLightTheme })

const Grid = React.forwardRef<HTMLDivElement, GridProps>(function Grid({
  children,
  className,
  hasMore = false,
  fixed = false,
  infiniteLoading = false,
  loadMore,
  scrollThreshold,
  size = 'medium',
  testId,
  width,
  ...props
}, ref) {
  const classes = useStyles({ width, fixed });
  const _ref = useRef<HTMLDivElement | null>();
  useInfiniteLoader(hasMore, infiniteLoading, _ref, scrollThreshold, loadMore);

  return (
    <TableContainer
      {...props}
      classes={{ root: classes.container }}
      className={className}
      data-component="onx-grid"
      data-test-id={testId}
      ref={(el) => {
        _ref.current = el;
        if (!!ref) {
          if (typeof ref === 'function') ref(el);
          else ref.current = el;
        }
      }}>
      <Table classes={{ root: classes.table }}>
        { 
          React.Children.map(children,
            (child) => React.isValidElement(child) ? React.cloneElement(child, { size }) : child
          )
        }
      </Table>
    </TableContainer>
  );
});

export default Grid;
