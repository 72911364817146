import * as React from 'react';
import { SectionHeader } from '..';
import { SheetTabs } from '../../SectionTabs/SheetTabs';
import { fonts } from '../../../constants';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { SheetHeadProps } from "../../../types";
import clsx from 'clsx';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      backgroundColor: palette.bkg2.main,
      borderRadius: '8px 8px 0 0',
      width: '100%',
    },
    heading: {
      height: '48px',
    },
    titleContainer: {
      height: '48px',
      padding: '17px 12px 13px 20px',
    },
    title: {
      ...fonts.displayFonts.capsMedium,
    },
    extraContent: {
      height: '48px',
      padding: '17px 0px 13px 0px',
    },
    extraContentItems: {
      alignItems: 'center',
      display: 'inline-flex',
      padding: '6px 0px',
      '& > button': {
        marginRight: '10px',
      },
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxSheetHead' });

function SheetHead({
  variant = "regular",
  classes = {},
  className,
  testId,
  ...props
}: SheetHeadProps) {
  const cls = useStyles();

  const TabsComponent = props.TabsProps ? <SheetTabs {...props.TabsProps} /> : null;

  return <SectionHeader
    classes={{
      root: clsx(cls.root, classes.root),
      heading: clsx(cls.heading, classes.heading),
      titleContainer: clsx(cls.titleContainer, classes.titleContainer),
      title: clsx(cls.title, classes.title),
      extraContent: clsx(cls.extraContent, classes.extraContent),
      extraContentItems: clsx(cls.extraContentItems, classes.extraContentItems),
    }}
    className={className}
    data-component="onx-sheet-head"
    data-test-id={testId}
    tabs={TabsComponent}
    variant={variant}
    {...props}
  />;
}

export default SheetHead;
