import * as React from 'react';
import {
  useEffect,
  useState
} from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Divider,
  Menu,
  MenuItem
} from '@material-ui/core';
import { Checkbox } from '../Checkbox';
import { BellIcon } from '../Icon';
import { TextButton } from '../TextButton';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import {
  BrandedProps,
  MastheadProps,
  UserAvatarProps,
} from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    appBarRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '12px 32px',
      color: palette.ink.main,
      boxShadow: 'none',
    },
    root: {
      fontFamily: fonts.fontFamily,
    },
    appBar: ({ isBranded }: BrandedProps) => ({
      backgroundColor: isBranded
        ? palette.brandCanvas.main
        : palette.page.main,
      borderBottom: isBranded
        ? 'none'
        : `1px solid ${palette.borderTrans.main}`,
      color: isBranded
        ? palette.brandInk.main
        : palette.ink.main,
      height: '72px',
      padding: '0 32px',
      transition: 'box-shadow 0.5s',
    }),
    shadow: {
      boxShadow: `0px 2px 5px 1px ${palette.bkg3.main}`,
    },
    placeholder: {
      height: '72px',
      marginBottom: '4px',
    },
    left: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    right: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    logoContainer: {
      alignItems: 'center',
      display: 'flex',
      marginRight: '80px',
    },
    logoImage: {
      maxWidth: '160px',
      maxHeight: '40px',
    },
    navigationItems: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      ...fonts.displayFonts.capsMedium,
    },
    navigationItem: {
      color: palette.brandLevel3.main,
      cursor: 'pointer',
      margin: '29px 40px 26px 0',
      '&:hover': {
        borderBottom: '3px solid',
        borderBottomColor: palette.borderTrans.main,
        color: palette.brandLevel2.main,
        paddingBottom: '3px',
        marginBottom: '20px !important',
      },
      '&:last-child': {
        marginRight: '0',
      },
    },
    navigationItemActive: {
      borderBottom: `3px solid ${palette.system3.main} !important`,
      color: palette.brandLevel1.main,
      paddingBottom: '3px',
      marginBottom: '20px !important',
    },
    user: {
      alignItems: 'center',
      borderRadius: '4px',
      cursor: 'pointer',
      display: 'flex',
      height: '72px',
      justifyContent: 'center',
      marginleft: '80px',
    },
    userInfo: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: palette.brandLevel2.main,
        },
        '& $userDomain': {
          color: palette.brandLevel2.main,
        },
      },
    },
    userDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    userDomain: {
      color: palette.brandLevel3.main,
      ...fonts.displayFonts.xxSmall,
    },
    userName: {
      color: palette.brandLevel1.main,
      ...fonts.displayFonts.xSmallStrong,
    },
    userAlertButton: {
      margin: '0 0 0 12px',
      '& .MuiSvgIcon-root': {
        color: palette.system3.main,
      },
    },
    userAlertButtonDisabled:{
      '& .MuiSvgIcon-root': {
        color: palette.brandLevel4.main,
      },
    },
    showNotificationsCheckbox: {
      padding: '0px',
    },
    settingsMenuItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      minHeight: '36px',
      minWidth: '200px',
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxMasthead' });

const useAvatarStyles = makeStyles(({ palette }) => {
  return {
    userAvatar: {
      color: palette.brandLevel4.main,
      marginRight: '4px',
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxUserAvatar'});

const UserAvatar: React.FC<UserAvatarProps> = ({
  Avatar,
  userName
}) => {
  const classes = useAvatarStyles();

  if (!Avatar) return null;

  return typeof Avatar === 'string' 
    ? <img
      alt={userName}
      className={classes.userAvatar}
      src={Avatar} />
    : <Avatar
      className={classes.userAvatar}
      size="xLarge" />
};

const Masthead: React.FC<MastheadProps> = ({
  activeNavigationItem,
  className,
  isBranded = false,
  logo,
  logoStyles = {},
  navigationItems,
  notifications,
  onNavigationItemClick,
  onSettingsItemClick,
  onShowNotificationsClick,
  settingsItems,
  showAllAlerts = true,
  showNotifications,
  testId,
  user,
  withFixedShadow,
  ...props
}) => {
  const classes = useStyles({ isBranded });
  const [useShadowClass, setUseShadowClass] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const notificationsCount = notifications.length;
  const newNotificationsCount = notifications.filter(item => !item.read).length;

  useEffect(() => {
    const handleScroll = () => {
      setUseShadowClass(window.scrollY > 0);
    }
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={clsx(
        classes.root,
        className
      )}
      data-component="onx-masthead"
      data-test-id={testId}
    >
      <AppBar
        className={clsx([
          classes.appBar,
          {
            [classes.shadow]: withFixedShadow || useShadowClass,
          }
        ])}
        classes={{root: classes.appBarRoot}}
        position="fixed"
        color="default"
        {...props}
      >
        <div className={classes.left}>
          <div className={classes.logoContainer}>
            <img
              alt={logo.title}
              className={classes.logoImage}
              src={logo.src}
              style={logoStyles}
            />
          </div>
          <div className={classes.navigationItems}>
            {navigationItems.map(item => (
              <div
                className={clsx([
                  classes.navigationItem,
                  {
                    [classes.navigationItemActive]: activeNavigationItem === item.id,
                  },
                ])}
                key={item.id}
                onClick={() => onNavigationItemClick && onNavigationItemClick(item.id)}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.user}>
            <div
              className={classes.userInfo}
              onClick={handleClick}
            >
              <UserAvatar Avatar={user.avatar} userName={user.name} />

              <div className={classes.userDetails}>
                <div className={classes.userDomain}>
                  {user.domain}
                </div>

                <div className={classes.userName}>
                  {user.name}
                </div>
              </div>
            </div>

            <TextButton
              className={clsx(
                classes.userAlertButton,
                notificationsCount <= 0 && classes.userAlertButtonDisabled
              )}
              disabled={notificationsCount <= 0}
              IconComponent={BellIcon}
              onClick={onShowNotificationsClick}
              selected={showNotifications}>
              { newNotificationsCount > 0 && newNotificationsCount }
            </TextButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            getContentAnchorEl={null}
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl)}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            {
              showAllAlerts &&
                <div>
                  <MenuItem
                    className={classes.settingsMenuItem}
                    onClick={() => onShowNotificationsClick}
                  >
                    Show all alerts
                    <Checkbox
                      checked={showNotifications}
                      className={classes.showNotificationsCheckbox}
                      variant="single"
                    />
                  </MenuItem>
                  <Divider />
                </div>
            }
            {settingsItems.map(({ value, name, withDivider = false }, i) => (
              <div key={i}>
                <MenuItem
                  className={classes.settingsMenuItem}
                  onClick={() => {
                    onSettingsItemClick && onSettingsItemClick(value)
                    handleClose()
                  }}
                  value={value}
                >
                  {name}
                </MenuItem>
                {withDivider && (<Divider />)}
              </div>
            ))}
          </Menu>
        </div>
      </AppBar>

      <div className={classes.placeholder} />

    </div>
  );
};

export default Masthead;
