import * as React from 'react';
import { Dialog } from '@material-ui/core';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { CloseIcon } from '../../Icon';
import { IconButton } from '../../IconButton';
import { Sheet } from '../../Layouts';
import { SheetHead } from '../../SectionHeader/SheetHead';
import { NotificationsInspectorProps } from '../../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    dialog: {
      boxSizing: 'border-box',
      '& *': {
        boxSizing: 'inherit',
      },
    },
    paper: {
      backgroundColor: palette.page.main,
      border: `1px solid ${palette.borderStrong.main}`,
      boxShadow: 'none',
      height: '400px',
    },
    sheet: {
      height: '100%',
    },
  };
}, { name: 'OnxNotificationsInspector', defaultTheme: defaultLightTheme });

export function NotificationsInspector ({
  children,
  className,
  onClose,
  open = false,
  testId,
  title,
  ...props
}: NotificationsInspectorProps) {

  const classes = useStyles();

  return (
    <Dialog
      {...props}
      className={className}
      classes={{ root: classes.dialog }}
      data-component="onx-notifications-inspector"
      data-test-id={testId}
      maxWidth="xl"
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
      open={open}>
        <Sheet
          childrenHead={(
            <SheetHead extraContent={(
              <IconButton IconComponent={CloseIcon} onClick={onClose} size="medium" />
            )} title={title}/>
          )}
          className={classes.sheet}>
          { children }
        </Sheet>
    </Dialog>
  );
}

export default NotificationsInspector;
