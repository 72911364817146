import * as React from 'react';
import { useState } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateTimePickerToolbar from './DateTimePickerToolbar';
import { ClockIcon } from '../Icon';
import {
  defaultLightTheme,
  makeStyles
} from '../../themes';
import { InlineDatePickerProps } from '../../types';

const useStyles = makeStyles(({ palette })=> {
  return {
    inlineTimePicker: {
      border: `1px solid ${palette.borderStrong.main}`,
      boxSizing: 'border-box',
      boxShadow: 'none',
      marginTop: '-32px',
      width: '292px',

      '& .MuiPickersBasePicker-container': {
        border: 'none',
        boxSizing: 'border-box',
        padding: '12px 16px',
        width: '290px',
        '& .MuiPickersBasePicker-pickerView': {
          overflowX: 'unset',
          '& .MuiPickersClock-container': {
            margin: 0,
            '& .MuiPickersClock-clock': {
              '& .MuiPickersClock-pin': {
                backgroundColor: palette.borderStrong.main,
              },
              '& .MuiPickersClockPointer-pointer': {
                backgroundColor: palette.borderStrong.main,
                '& .MuiPickersClockPointer-thumb': {
                  backgroundColor: palette.borderStrong.main,
                  borderColor: palette.borderStrong.main,
                },
              },
            },
          },
        },
      },
    },
    arrowButton: {
      '&:hover': {
        background: 'none',
      },
    },
  }
}, { name: 'OnxTimePicker', defaultTheme: defaultLightTheme });

class CustomDateFnsUtils extends DateFnsUtils {
  getWeekdays() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}

const TimePicker = ({
  className,
  disabled = false,
  format = 'hh:mm a',
  label,
  onChange,
  size = 'medium',
  testId,
  variant = 'inline',
  value = null,
  ...props
}: InlineDatePickerProps) => {
  const [ hideLabel, setHideLabel ] = useState<boolean>(false);
  const classes = useStyles();

  const toggleLabel = () => {
    setHideLabel(!hideLabel);
  }

  const refusePattern = () => {
    // Allows only a-z and 0-9 char
    return /[^a-zA-Z0-9]+/gi;
  };

  // TODO: The clock dialog is actually 40 pixel wider than required by design (292px instead of 252px) and that's because
  // the clock has a fixed width/hegiht and the numbers are placed in a fixed position, with no chance to change it other than
  // manually place each number. We hope this can change in the future but I leave here a few links for future reference:
  // 
  // - Fixed Clock size: https://github.com/mui-org/material-ui-pickers/blob/next/lib/src/views/Clock/Clock.tsx#L49-L50
  // - Fixed Number positions: https://github.com/mui-org/material-ui-pickers/blob/next/lib/src/views/Clock/ClockNumber.tsx#L11-L34
  //
  // By changing the Clock size manually, make the numbers to be missaligned from the clock circle.
  return (
      <MuiPickersUtilsProvider utils={CustomDateFnsUtils}>
        <KeyboardTimePicker
          {...props}
          ampm
          autoOk={true}
          className={className}
          data-component="onx-timepicker"
          date-test-id={testId}
          disabled={disabled}
          format={format}
          inputVariant="outlined"
          KeyboardButtonProps={{
            classes: {
              root: classes.arrowButton,
            }
          }}
          keyboardIcon={<ClockIcon size="medium" />} // TODO: Replace this icon with Clock Icon once available in Icons library
          label={!hideLabel ? label : ''}
          onChange={date => onChange(date)}
          onClose={toggleLabel}
          onOpen={toggleLabel}
          openTo="hours"
          PopoverProps={{
            ...props.PopoverProps && props.PopoverProps,
            classes: {
              paper: classes.inlineTimePicker
            }
          }}
          refuse={refusePattern()}
          ToolbarComponent={DateTimePickerToolbar}
          value={value}
          variant={variant}
          views={['hours', 'minutes']}
        />
      </MuiPickersUtilsProvider>
    );
};

export default TimePicker;
