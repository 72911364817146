import * as React from 'react';
import { useState } from 'react';
import { PopupFilter } from '../../Filters';
import { Button } from '../../Button';
import { DropdownSelector } from '../../DropdownSelector';
import { Input } from '../../Input';
import { Popover } from '../../Popover';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { GridFilterStringMatchProps } from '../../../types';

const useStyles = makeStyles(() => {
  return {
    popover: {
      '& .MuiPopover-paper': {
        maxWidth: 'none',
      },
    },
    form: {
      display: 'flex',

      '& > *': {
        marginLeft: '8px',

        '&:first-child': {
          marginLeft: 0,
        },
      },
    },
  }
}, { name: 'OnxGridFilterStringMatch', defaultTheme: defaultLightTheme});

function GridFilterStringMatch ({
  className,
  condition,
  filterOptions,
  iconSize = 'xSmall',
  onOpen,
  onClose,
  value,
  testId
}: GridFilterStringMatchProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [filterCondition, setFilterCondition] = useState<string | undefined>(condition);
  const [filterValue, setFilterValue] = useState<string | undefined>(value);

  const classes = useStyles();

  const handleOpen = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
    onOpen && onOpen();
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    onClose(filterCondition, filterValue);
  }

  const handleClear = () => {
    setFilterCondition(undefined);
    setFilterValue(undefined);
  }

  return (
    <PopupFilter
      active={!!filterValue}
      className={className}
      data-component="onx-grid-filter-string-match"
      data-test-id={testId}
      size={iconSize}
      onClick={handleOpen}>
      <Popover
        anchorEl={anchorEl}
        className={classes.popover}
        label=""
        onClose={handleClose}
        open={open}>
        <div className={classes.form}>
          <DropdownSelector
            label="Condition"
            multiple={false}
            onChange={(newCondition: string | undefined) => { setFilterCondition(newCondition) }}
            items={filterOptions}
            size="medium"
            value={filterCondition} />
          <Input
            label="Value"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setFilterValue(e.target.value) }}
            size="small"
            type="text"
            value={filterValue} />
          <Button
            color="outline"
            onClick={handleClear}>
            Clear
          </Button>
        </div>
      </Popover>
    </PopupFilter>
  );
}

export default GridFilterStringMatch;
