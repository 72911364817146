import * as React from 'react';
import { FilterIcon } from '../Icon';
import { IconButton } from '../IconButton';
import { PopupFilterProps } from '../../types';

function PopupFilter ({
  active = false,
  children,
  className,
  IconComponent,
  onClick,
  size = 'xSmall',
  testId,
}: PopupFilterProps) {

  return (
    <>
      <IconButton
        className={className}
        data-component="onx-popup-filter"
        data-test-id={testId}
        IconComponent={IconComponent ? IconComponent : FilterIcon}
        onClick={onClick}
        selected={active}
        size={size} />
        { children }
    </>
  );
}

export default PopupFilter;
