import * as React from 'react';
import { fonts } from '../../../constants';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { GenericSectionTabsProps } from '../../../types';
import { SectionTabs } from '..';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    root: {
      minHeight:'48px',
    },
    indicator: {
      height: 2,
      backgroundColor: palette.system3.main,
    },
    labelContainer: {
      color: palette.level3.main,
      ...fonts.displayFonts.capsMedium,
      textTransform: 'uppercase',
    },
    tab: {
      backgroundColor: palette.bkg2.main,
      height: '48px',
      padding: '15px 24px 11px 24px',
      '&:hover': {
        '& .MuiTab-wrapper > div': {
          color: palette.level2.main,
        },
        '& .MuiTab-wrapper > div::after': {
          background: palette.level4.main,
          bottom: '-4px',
          height: '2px',
        },
      },
      '&:first-child': {
        borderTopLeftRadius: '8px',
      },
    },
    activeTab: {
      '& .MuiTab-wrapper > div': {
        color: `${palette.level1.main} !important`,
      },
      '&:hover': {
        '& .MuiTab-wrapper > div': {
          color: palette.level1.main,
        },
      },
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxSheetTabs' });

function SheetTabs({
  className,
  testId,
  ...props
}: GenericSectionTabsProps) {

  const classes = useStyles();

  return <SectionTabs
    classes={classes}
    className={className}
    data-component="onx-sheet-tabs"
    data-test-id={testId}
    {...props}
  />;
}

export default SheetTabs;
