import * as React from 'react';
import clsx from 'clsx';
import { TextButton } from '../TextButton'
import { defaultLightTheme, makeStyles } from '../../themes';
import { TextToggleClassKey, TextToggleProps } from 'types/textToggle';
import { fonts } from '../../constants';

const useStyles = makeStyles<{}, TextToggleClassKey>(({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1px',
  },
  button: {
    margin: 0,
    overflow: 'hidden',
    '&:hover': {
      '& .MuiButton-label': {
        color: palette.level2.main,
      },
    },
    '&.Mui-disabled': {
      '& .MuiButton-label': {
        color: palette.level4.main,
      },
    },
    '& .MuiButton-label': {
      color: palette.level3.main,
    },
    '& span': {
      zIndex: 1,
    },
  },
  buttonSelected: {
    borderColor: 'transparent',
    '& .MuiButton-label': {
      ...fonts.displayFonts.capsMedium,
      color: palette.system3.main,
    },
    '&:hover': {
      borderColor: 'transparent',
      '& .MuiButton-label': {
        color: palette.system3.main,
      },
    },
    '&.system1': {
      color: palette.system1.main,
      '& .MuiButton-label': {
        color: palette.system1.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.system1.main,
        },
      },
    },
    '&.system2': {
      color: palette.system2.main,
      '& .MuiButton-label': {
        color: palette.system2.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.system2.main,
        },
      },
    },
    '&.system3': {
      color: palette.system3.main,
      '& .MuiButton-label': {
        color: palette.system3.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.system3.main,
        },
      },
    },
    '&.buy': {
      color: palette.buy.main,
      '& .MuiButton-label': {
        color: palette.buy.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.buy.main,
        },
      },
    },
    '&.sell': {
      color: palette.sell.main,
      '& .MuiButton-label': {
        color: palette.sell.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.sell.main,
        },
      },
    },
  },
  divider: {
    backgroundColor: palette.borderTrans.main,
    height: '20px',
    width: '1px',
    padding: 0,
    margin: '0px 4px 0px 4px',
    transitionProperty: 'background-color, border-color',
    transitionDuration: '.25s',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
}), { name: 'OnxTextToggle', defaultTheme: defaultLightTheme });

const TextToggle = React.forwardRef<HTMLDivElement, TextToggleProps>(function TextToggle({
  ButtonProps = {},
  className,
  classes: inheritedClasses = {},
  disabled = false,
  onChange,
  selectedIndex,
  tabs = [],
  testId,
  ...props
}, ref) {

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.container,
        inheritedClasses.container,
        className
      )}
      data-component="onx-toggle"
      data-test-id={testId}
      ref={ref}
      {...props}
    >
      {tabs.map(({ label, variant, key }, i) => {
        return (
          <React.Fragment key={key ?? i}>
            <TextButton
              onClick={() => onChange(i, label, key)}
              className={clsx(
                classes.button,
                inheritedClasses.button,
                selectedIndex === i && [
                  classes.buttonSelected,
                  inheritedClasses.buttonSelected
                ],
                !variant ? 'system3' : variant,
              )}
              disabled={disabled}
              key={key ?? i}
              {...ButtonProps}
            >
              {label}
            </TextButton>
            {i < tabs.length - 1 && 
              <div
                className={clsx(classes.divider, inheritedClasses.divider)}
              />
            }
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default TextToggle;
