import * as React from 'react';
import { Tooltip as TooltipMui } from '@material-ui/core';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { TooltipProps } from '../../types';
import clsx from 'clsx';


// fontFamily: fonts.fontFamily,
// ...fonts.displayFonts.small,
// backgroundColor: palette.canvas.main,
// border: `solid 1px ${palette.borderStrong.main}`,
// color: palette.level1.main,
// padding: '2px 8px',
// marginTop: '0px',
// maxWidth: '300px',

const useStyles = makeStyles(({ palette }) => ({
  tooltip: {
    fontFamily: fonts.fontFamily,
    ...fonts.displayFonts.small,
    backgroundColor: ({ alt }: Partial<TooltipProps>) => alt
      ? palette.level2.main
      : palette.page.main,
    border: `solid 1px ${palette.borderStrong.main}`,
    color: ({ alt }: Partial<TooltipProps>) => alt
      ? palette.canvas.main
      : palette.level2.main,
    padding: '1px 7px',
    marginTop: '0px',
    maxWidth: '300px',
  },
}), { defaultTheme: defaultLightTheme, name: 'OnxTooltip' });

function Tooltip({
  alt = false,
  className,
  testId,
  ...props
}: TooltipProps) {
  const classes = useStyles({ alt });
  const { tooltip: tooltipCls, ...restCls } = props.classes ?? {};

  return <TooltipMui
    {...props}
    classes={{
      tooltip: clsx(classes.tooltip, tooltipCls),
      ...restCls
    }}
    className={className}
    data-component="onx-tooltip"
    data-test-id={testId}
  />
};

export default Tooltip;
