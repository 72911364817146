import * as React from 'react';
import {
  useEffect,
  useRef
} from 'react';
import clsx from 'clsx';
import { HistoryIcon } from '../Icon';
import { TextButton } from '../TextButton';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { NotificationsProps } from '../../types';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;

  return {
    root: ({ triggerTopOffset }: Partial<NotificationsProps>) => ({
      background: palette.canvas.main,
      borderLeft: `1px solid ${palette.border.main}`,
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100% - ${triggerTopOffset}px)`,
      position: 'absolute',
      right: '0',
      top: `${triggerTopOffset}px`,
      transition: '.25s ease all',
      width: '360px',
    }),
    closed: {
      boxShadow: 'none',
      transform: 'translateX(100%)',
    },
    header: {
      backgroundColor: palette.bkg2.main,
      borderBottom: `1px solid ${palette.border.main}`,
      flex: '0 1 44px',
      padding: '12px 16px 12px 12px',
      width: '100%',
      ...fonts.displayFonts.mediumStrong,
    },
    content: {
      flex: '1 1 auto',
      overflow: 'auto',
      width: '100%',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.hov1.main,
        borderRadius: '4px',
        '&:hover': {
          background: palette.hov2.main,
        },
      },
      '&::-webkit-scrollbar-track': {
        background: palette.canvas.main,
        borderRadius: 0,
      },
    },
    footer: {
      backgroundColor: palette.bkg2.main,
      borderTop: `1px solid ${palette.border.main}`,
      display: 'flex',
      flex: '0 1 48px',
      justifyContent: 'center',
      padding: '12px 16px 12px 20px',
      width: '100%',
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxNotifications' });

function Notifications ({
  allNotificationsAction,
  children,
  className,
  onClose,
  onOpen,
  open = false,
  testId,
  title,
  triggerTopOffset = 0
}: NotificationsProps) {
  const classes = useStyles({ triggerTopOffset });

  const currentOpenState = useRef(open);
  useEffect(() => {
    if (open !== currentOpenState.current) {
      currentOpenState.current = open;

      if (!open && onClose) {
        onClose();
      }

      if(open && onOpen) {
        onOpen();
      }
    }
  }, [onClose, onOpen, open]);

  return (
    <div
      className={clsx(
        classes.root,
        className,
        !open && classes.closed
      )}
      data-component="onx-notifications"
      data-test-id={testId}
    >
      <div className={classes.header}>
        { title }
      </div>
      <div className={classes.content}>

        { children }

      </div>
      <div className={classes.footer}>
        {
          allNotificationsAction &&
          <TextButton
            IconComponent={HistoryIcon}
            onClick={allNotificationsAction}
          >
            All Notifications
          </TextButton>
        }
      </div>
    </div>
  );
}

export default Notifications;
