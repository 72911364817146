import * as React from 'react';
import clsx from 'clsx';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { SectionHeaderProps } from '../../types';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;

  return {
    root: {
      width: '100%',
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    titleContainer: {
      alignItems: 'center',
      borderBottom: `1px solid ${palette.border.main}`,
      display: 'inline-flex',
      minWidth: '100px',
    },
    title: {
      color: palette.level1.main,
      fontFamily: fonts.fontFamily,
    },
    titleCounter: {
      alignItems: 'center',
      fontFamily: fonts.fontFamily,
      ...fonts.displayFonts.counterMedium,
      border: `1px solid ${palette.borderTrans.main}`,
      borderRadius: '4px',
      color: ({ variant }: Pick<SectionHeaderProps, 'variant'>) => palette[variant === 'system' ? 'system3' : 'level2'].main,
      display: 'flex',
      marginLeft: '4px',
      padding: '0px 3px',
    },
    extraContent: {
      borderBottom: `1px solid ${palette.border.main}`,
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    subheading: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px',
      width: '100%',
    },
    tabs: {
      backgroundColor: palette.level1.main,
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
    },
    tab: {
      border: `1px solid ${palette.border.main}`,
      '&.MuiTab-textColorInherit.Mui-selected': {
        borderBottomColor: palette.canvas.main,
      },
      '&.MuiTab-textColorInherit.Mui-selected>.MuiTab-wrapper': {
        borderBottom: `3px solid ${palette.system3.main}`,
      },
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxSectionHeader' });

function SectionHeader({
  classes: inheritedClasses,
  className,
  extraContent,
  testId,
  title = '',
  titleCounter,
  tabs,
  variant = "regular"
}: SectionHeaderProps) {
  const classes = useStyles({ variant });

  return (
    <div
      className={clsx(
        classes.root,
        inheritedClasses.root,
        className
      )}
      data-component="onx-section-header"
      data-test-id={testId}
    >
      <div className={clsx(
        classes.heading,
        inheritedClasses.heading
      )}>
        {
          tabs
            ? tabs
            : (
              <div className={clsx(
                classes.titleContainer,
                inheritedClasses.titleContainer
              )}>
                <div className={clsx(
                  classes.title,
                  inheritedClasses.title
                )}>
                  {title}
                </div>
                {
                  (titleCounter !== undefined && titleCounter !== null) &&
                    <div className={classes.titleCounter}>
                      {titleCounter}
                    </div>
                }
              </div>
            )
        }
        <div className={clsx(
          classes.extraContent,
          inheritedClasses.extraContent
        )}>
          {
            extraContent &&
              <div className={inheritedClasses.extraContentItems}>
                {extraContent}
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default SectionHeader;

