import React from 'react';
import { SectionHeaderWithControllerProps } from '../../types';
import { PanelHead } from '../SectionHeader/PanelHead';
import { SheetHead } from '../SectionHeader/SheetHead';
import SectionController from '../SectionController/SectionController';

function SectionHeaderWithController({
  title,
  type
}: SectionHeaderWithControllerProps) {
  const Header = type === 'panel'
    ? <PanelHead title={title} />
    : <SheetHead title={title} />;

  return (
    <div>
      {Header}
      <SectionController 
        showDateRangeControllers
      />
    </div>
  );
};

export default SectionHeaderWithController;
