import * as React from 'react';
import {
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import {
  Menu as MuiMenu,
  TextField,
} from '@material-ui/core';
import { defaultLightTheme, makeStyles } from '../../themes';
import { opacities } from '../../constants';
import MenuItem from './MenuItem/MenuItem';
import MenuSection from './MenuSection/MenuSection';
import { Checkbox } from '../Checkbox';
import { MenuDownIcon } from '../Icon';
import { MenuProps, MenuListItem } from '../../types';
import { PopupButton } from '../PopupButton';
import { Tooltip } from '../Tooltip';

const useStyles = makeStyles(({ palette }) => {
  return {
    paper: {
      backgroundColor: palette.canvas.main,
      borderColor: palette.borderStrong.main,
      boxShadow: 'none',
      boxSizing: 'border-box',
      paddingBottom: '6px',
      width: '252px',
      '& *': {
        boxSizing: 'inherit',
      },
    },
    popoverPaper: {
      maxWidth: '300px',
      border: `1px solid ${palette.ink.main}${opacities[30]}`,
      backgroundColor: palette.canvas.main,
    },
    medium: {
      marginTop: '-4px',
      width: '200px',
    },
    list: {
      padding: 0,
    },
    pinned: {
      backgroundColor: palette.canvas.main,
      paddingTop: '6px',
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    pinnedSelected: {
      backgroundColor: palette.canvas.main,
      position: 'sticky',
      top: 0,
      zIndex: 2,
      '&.MuiListItem-button': {
        '&:hover': {
          backgroundColor: `${palette.canvas.main} !important`,
        },
      },
    },
    content: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    filterItem: {
      backgroundColor: palette.canvas.main,
      padding: '0px',
      position: 'sticky',
      top: 0,
      zIndex: 3,
      '&.MuiListItem-button': {
        '&:hover': {
          backgroundColor: `${palette.canvas.main} !important`,
        },
      },
    },
    filterTextField: {
      padding: '9px 12px 8px 16px',
      width: '100%',
      zIndex: 3,
    },
    divider: {
      backgroundColor: palette.border.main,
      height: '1px',
      margin: '6px 0',
      width: '100%',
    },
  };
}, { name: 'OnxMenu', defaultTheme: defaultLightTheme })

function Menu ({
  anchorEl: propAnchorEl,
  className,
  classes = {},
  customTrigger = false,
  items,
  filterable = false,
  MenuItemProps = {},
  multiple = false,
  onChange,
  onClose,
  onOpen,
  open,
  placeholder = '',
  popupButtonSize = 'medium',
  popupClasses = {},
  renderLabel,
  showClear = false,
  showSelectAll = false,
  showSelectedIndicator = false,
  showSelectedValue = false,
  size = 'large',
  testId,
  value,
  ...props
}: MenuProps) {  
  const [filter, setFilter] = useState<string>('');
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const firstItemRef = useRef<HTMLLIElement | null>(null);

  const cls = useStyles();
  const {
    paper: paperCls,
    list: listCls,
    clear: clearCls,
    divider: dividerCls,
    filterItem: filterItemCls,
    filterTextField: filterTextFieldCls,
    item: itemCls,
    pinned: pinnedCls,
    pinnedSelected: pinnedSelectedCls,
    popup: popupCls,
    section: sectionCls,
    selectAll: selectAllCls,
  } = classes;

  const handleOpen = (e: React.MouseEvent) => {
    onOpen?.(e);
  }

  const handleClose = () => {
    onClose?.();
  }

  const anchorEl = customTrigger ? propAnchorEl : anchorRef.current;

  const handleChange = useCallback((itemValue: unknown) => {
    if (multiple && Array.isArray(value)) {
      if (value.includes(itemValue)) {
        // pop
        onChange(value.filter((selectedValue: unknown) => selectedValue !== itemValue));
      } else {
        // push
        onChange([...value, itemValue]);
      }
    } else {
      onChange(itemValue, true);
    }
  }, [onChange, multiple, value]);

  const handleClear = useCallback(() => {
    onChange(multiple ? [] : null, true);
  }, [onChange, multiple]);

  const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e?.target?.value || '');
  }, [setFilter]);

  const keyedItemsReducer = useCallback((keyedItems: Record<string, MenuListItem>, item: MenuListItem): Record<string, MenuListItem> => {
    if (item.items) {
      return item.items.reduce(keyedItemsReducer, keyedItems);
    }
    if (item.type !== 'divider') {
      keyedItems[item.value as any] = item;
    }
    return keyedItems;
  }, []);

  const keyedItems = useMemo(() => {
    return items.reduce(keyedItemsReducer, {});
  }, [items, keyedItemsReducer])

  const selectableValues = useMemo(() => Object.keys(keyedItems), [keyedItems]);

  const allSelected: boolean = useMemo(() => {
    if (!multiple) return false;
    return Array.isArray(value) && value.length === selectableValues.length;
  }, [value, multiple, selectableValues]);

  const handleSelectAll = useCallback(() => {
    if (allSelected) {
      onChange([], true);
    } else {
      onChange(selectableValues);
    }
  }, [allSelected, onChange, selectableValues]);

  const partialSelected: boolean = useMemo(() => {
    if (!multiple) return false;
    return Array.isArray(value) && value.length > 0;
  }, [value, multiple]);

  const renderSelectedValue = useMemo(() => {
    return renderLabel
      ? renderLabel(value)
      : multiple && Array.isArray(value)
        ? value.length === 1 ? keyedItems[value[0] as any].name : `${value.length} Selected`
        : keyedItems[value as any]?.name ?? '';
  }, [multiple, renderLabel, keyedItems, value]);

  const showLabel = useMemo(() => {
    return Array.isArray(value)
      ? value.length > 0
      : value !== null && value !== undefined
  }, [value]);

  const isCheckboxSelected = useCallback((item: MenuListItem) => {
    return (multiple && Array.isArray(value))
      ? value.some((v) => v === item.value)
      : value === item.value;
  }, [multiple, value])

  const MenuDivider = useCallback(() => <div className={clsx(cls.divider, dividerCls)} />, [cls.divider, dividerCls]);

  const tooltip = multiple && Array.isArray(value) && value.length > 1
  ? <div>{value.map((v: unknown, i: number) => <div key={i}>{keyedItems[v as any].name}</div>)}</div>
  : '';

  return (
    <>
      {
        !customTrigger && 
          <Tooltip placement="bottom" title={tooltip}>
            <PopupButton
              ref={anchorRef}
              className={popupCls}
              classes={popupClasses}
              EndIconComponent={MenuDownIcon}
              onClick={handleOpen}
              children={showLabel ? renderSelectedValue : placeholder}
              italic={showLabel}
              selected={open}
              size={popupButtonSize}
            /> 
          </Tooltip>
      }
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        classes={{
          list: clsx(cls.list, listCls),
          paper: clsx(
            cls.paper,
            size === 'medium' && cls.medium,
            paperCls,
          ),
        }}
        className={className}
        data-component="onx-menu"
        data-test-id={testId} 
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        PopoverClasses={{
          paper: cls.popoverPaper,
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        {...props}>
        <div className={clsx(cls.pinned, pinnedCls)}>
          {
            filterable &&
            [
              <MenuItem className={clsx(cls.filterItem, filterItemCls)} key="filter">
                <TextField
                  autoFocus
                  className={clsx(cls.filterTextField, filterTextFieldCls)}
                  onBlur={() => firstItemRef.current?.focus()}
                  onChange={handleFilterChange}
                  onKeyDown={(e) => { e.stopPropagation() }}
                  size="small"
                  type="text"
                  value={filter} />
              </MenuItem>,
              <MenuDivider key="divider"/>
            ]
          }
          {
            showSelectedValue && placeholder !== "" &&
            [
              <MenuItem className={clsx(cls.pinnedSelected, pinnedSelectedCls)} key="selection">
                <span className={cls.content}>
                  {showLabel ? renderSelectedValue : placeholder}
                </span>
                {showSelectedIndicator && <MenuDownIcon size="medium" />}
              </MenuItem>,
              <MenuDivider key="divider"/>
            ]
          }
          {
            (showLabel && showClear) &&
            [
              <MenuItem className={clearCls} onClick={handleClear} key="clear">
                Clear
              </MenuItem>,
              <MenuDivider key="divider"/>
            ]
          }
          {
            (multiple && showSelectAll) &&
            [
              <MenuItem className={selectAllCls} onClick={handleSelectAll} key="selectAll">
                Select All
                <Checkbox
                  checked={allSelected}
                  indeterminate={partialSelected && !allSelected}
                  size="large"
                  variant="single" />
              </MenuItem>,
              <MenuDivider key="divider"/>
            ]
          }
        </div>
        {
          items.filter((item) => {
            return item.type !== 'item' || item.name?.toLowerCase().includes(filter.toLowerCase());
          }).map((item, key) => {
            if (item.items) {
              if (item.type !== 'section' && item.type !== 'collapsable') return null;
              const filteredSection = {
                ...item,
                items: item.items.filter((i) => i.name?.toLowerCase().includes(filter.toLowerCase())),
              }
              return (
                <MenuSection
                  collapsable={item.type === 'collapsable'}
                  className={sectionCls}
                  item={filteredSection}
                  itemClassName={itemCls}
                  key={key}
                  MenuItemProps={MenuItemProps}
                  multiple={multiple}
                  onClick={handleChange}
                  selectedValue={value} />
              );
            } else {
              return item.type === 'divider'
                ? <MenuDivider key="divider"/>
                : <MenuItem key={key} {...(key === 0 && { ref: firstItemRef })} onClick={handleChange} value={item.value} className={itemCls} {...MenuItemProps}>
                  <span className={cls.content}>
                    {item.name}
                  </span>
                  {
                    multiple &&
                    <Checkbox
                      checked={isCheckboxSelected(item)}
                      size="large"
                      variant="single" />
                  }
                </MenuItem>;
            }
          })
        }
      </MuiMenu>
    </>
  );
}

export default Menu;
