import * as React from 'react';
import clsx from 'clsx';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { defaultLightTheme as defaultTheme } from '../../themes';
import { opacities } from '../../constants/opacities';
import { BorderProps, Palette } from '../../types';
import { makeStyles } from '../../themes';

const nil = (v: any): boolean => v === null || v === undefined

export const getBorderSelector = (
  overlay: boolean = true,
  hover: boolean = false
): string => `&${hover ? ':hover' : ''}::${overlay ? 'after' : 'before'}`

export const makeBorderStyles = (
  overlay: boolean = true,
  hover: boolean = false,
  index: number = 0
) => makeStyles<BorderProps>(theme => ({
  border: ({ border, width = '1px', color = 'borderTrans', opacity = 100, radius, inset = '0px' }) => {
    const w = typeof width === 'number' ? `${width}px` : width;
    const pc = (theme.palette[color as keyof Palette] as PaletteColor)?.main;
    const o = pc && pc.length === 7 ? (opacities[opacity] ?? '') : '';
    const c = pc || color;
    const b = border || `${w} solid ${c}${o}`;
    const r = nil(radius) ? {} : {borderRadius: radius};
    return {
      position: 'relative',
      ...r,
      [getBorderSelector(overlay, hover)]: {
        borderRadius: 'inherit',
        border: b,
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        inset,
      },
    };
  },
}), { defaultTheme, name: 'OnxBorder', index });

export const useBorderStyles = makeBorderStyles();

export const Border = ({children, className, testId, ...props}: BorderProps) => {
  const cls = useBorderStyles(props);
  return <div className={clsx(cls.border, className)} data-test-id={testId}>{children}</div>
}

export default Border;
