import * as React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { InputLabel } from '@material-ui/core';
import { MenuDownIcon } from '../Icon';
import { Menu } from '../Menu';
import { fonts, opacities } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import {
  DropdownSelectorProps,
  MenuListItem
} from '../../types';
import { Tooltip } from '../Tooltip';

const useStyles = makeStyles(({ palette }) => {
  return {
    popoverPaper: {
      maxWidth: '300px',
      border: `1px solid ${palette.ink.main}${opacities[30]}`,
      backgroundColor: palette.canvas.main,
    },
    dropdown: {
      border: `1px solid ${palette.level4.main}`,
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'flex',
      fontFamily: fonts.fontFamily,
      height: '44px',
      justifyContent: 'space-between',
      padding: '12px 16px',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: '252px',
      '&:hover': {
        borderColor: palette.borderStrong.main,
        '& $label': {
          color: palette.level2.main,
        },
        '& $menuArrow': {
          color: palette.level2.main,
        },
      },
    },
    medium: {
      height: '37px',
      padding: '8px 16px 9px 16px',
      width: '200px',
      '& $label': {
        transform: 'translate(16px, 8px) scale(1)',
      },
      '& $labelShrink': {
        transform: 'translate(10px, -6px) scale(1)',
      }
    },
    content: {
      ...fonts.displayFonts.medium,
      color: palette.level1.main,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    label: {
      ...fonts.displayFonts.medium,
      color: palette.level3.main,
      left: 0,
      position: 'absolute',
      top: 0,
      transform: 'translate(16px, 12px) scale(1)',
    },
    labelShrink: {
      ...fonts.displayFonts.floatingLabel,
      backgroundColor: palette.canvas.main,
      padding: '0 4px',
      transform: 'translate(10px, -6px) scale(1)',
    },
    menuArrow: {
      color: palette.level3.main,
      marginLeft: '8px',
    },
    paper: {
      marginTop: '0px',
    },
    pinnedSelectedMedium: {
      height: '25px',
    },
    disabled: {
      '& $label': {
        color: palette.level4.main,
      },
      '& $content': {
        color: palette.level4.main,
      },
      '& $menuArrow': {
        color: palette.level4.main,
      },
      '&:hover': {
        borderColor: palette.level4.main,
        '& $label': {
          color: palette.level4.main,
        },
        '& $content': {
          color: palette.level4.main,
        },
        '& $menuArrow': {
          color: palette.level4.main,
        },
      }
    },
    error: {
      borderColor: palette.alert1.main,
      '& $label': {
        color: palette.alert1.main,
      },
      '& $content': {
        color: palette.level1.main,
      },
      '& $menuArrow': {
        color: palette.level2.main,
      },
      '&:hover': {
        borderColor: palette.alert1.main,
        '& $label': {
          color: palette.alert1.main,
        },
        '& $content': {
          color: palette.level1.main,
        },
        '& $menuArrow': {
          color: palette.level2.main,
        },
      }
    }
  };
}, { name: 'OnxDropdownSelector', defaultTheme: defaultLightTheme});

function DropdownSelector({
  className,
  disabled = false,
  error = false,
  items,
  label,
  onOpen,
  onChange,
  onClose,
  MenuProps = {},
  multiple = false,
  open,
  renderLabel,
  size = 'large',
  testId,
  value
}: DropdownSelectorProps) {
  const [localOpen, setLocalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hideLabel, setHideLabel] = useState(false);
  const dropdownRef = useRef(null);
  const { current: isControlled } = useRef(open !== undefined);
  const cls = useStyles();

  const _open = isControlled ? open as boolean : localOpen;

  useEffect(() => {
    setAnchorEl(dropdownRef.current);
  }, []);

  const toggleMenu = () => {
    if (!disabled) {
      if (_open) {
        setHideLabel(false);
        onClose?.();
      } else {
        setHideLabel(true);
        onOpen?.();
      }
      if (!isControlled) setLocalOpen(p => !p);
    }
  }

  const handleChange = (selectedValues: unknown) => {
    if (!multiple) {
      toggleMenu();
    }
    onChange(selectedValues);
  }

  const keyedItemsReducer = useCallback((keyedItems: Record<string, MenuListItem>, item: MenuListItem): Record<string, MenuListItem> => {
    if (item.items) {
      return item.items.reduce(keyedItemsReducer, keyedItems);
    }
    if (item.type !== 'divider') {
      keyedItems[item.value as any] = item;
    }
    return keyedItems;
  }, []);

  const keyedItems = useMemo(() => {
    return items?.reduce(keyedItemsReducer, {});
  }, [items, keyedItemsReducer])

  const renderSelectedValue = useMemo(() => {
    return renderLabel
      ? renderLabel(value)
      : multiple && Array.isArray(value)
        ? value.length === 1 ? keyedItems[value[0] as any].name : `${value.length} Selected`
        : keyedItems[value as any]?.name ?? '';
  }, [multiple, renderLabel, keyedItems, value])

  const shrinkLabel = useMemo(() => {
    return renderSelectedValue !== '';
  }, [renderSelectedValue]);

  const tooltip = multiple && Array.isArray(value) && value.length > 1
  ? <div>{value.map((v: unknown, i: number) => <div key={i}>{keyedItems[v as any].name}</div>)}</div>
  : '';

  return (
    <>
      <Tooltip placement="top" title={tooltip}>
        <div
          className={clsx(
            cls.dropdown,
            size === 'medium' && cls.medium,
            disabled && cls.disabled,
            error && cls.error,
            className
          )}
          data-component="onx-dropdown-selector"
          data-test-id={testId}
          onClick={() => toggleMenu()}
          ref={dropdownRef}>
          <span className={cls.content}>
            {
              !hideLabel &&
              <InputLabel
                classes={{
                  root: cls.label,
                  shrink: cls.labelShrink,
                }}
                shrink={shrinkLabel}>
                {label}
              </InputLabel>
            }
            {renderSelectedValue}
          </span>
          <MenuDownIcon
            className={cls.menuArrow}
            size="medium" />
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        classes={size === 'medium' ? { paper: cls.paper, pinnedSelected: cls.pinnedSelectedMedium } : { paper: cls.paper }}
        customTrigger
        items={items}
        multiple={multiple}
        onChange={handleChange}
        onClose={toggleMenu}
        open={_open}
        placeholder={label}
        PopoverClasses={{paper: cls.popoverPaper}}
        showSelectedIndicator
        showSelectedValue
        size={size}
        value={value}
        {...MenuProps}
      />
    </>
  );
}

export default DropdownSelector;
