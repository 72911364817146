import React from 'react';
import clsx from 'clsx';
import { Button as MaterialButton } from '@material-ui/core';
import { Button } from '../Button';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { TextButtonProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      boxShadow: 'none !important',
      borderRadius: '4px',
      height: '37px',
      minWidth: '90px',
      padding: '9px 16px',
      border: '1px solid transparent',
      '&::after': {
        borderRadius: '4px',
        content: "''",
        position: 'absolute',
        top: -1,
        left: -1,
        right: -1,
        bottom: -1,
      },
      '&$disabled': {
        backgroundColor: `${palette.level4.main} !important`,
      },
    },
    label: {
      ...fonts.displayFonts.capsMedium,
      zIndex: 1,
      color: palette.type === 'light'
        ? palette.canvas.main
        : palette.ink.main,
    },
    sizeLarge: {
      height: '44px',
      padding: '11px 20px',
      minWidth: '100px',
      '& .MuiButton-label': {
        ...fonts.displayFonts.capsLarge,
      },
    },
    sizeSmall: {
      height: '24px',
      minWidth: '50px',
      padding: '4px 8px',
      '& .MuiButton-label': {
        ...fonts.displayFonts.capsSmall,
      },
    },
    button: {
      background: 'none',
      borderColor: 'transparent',
      borderRadius: '2px',
      boxShadow: 'none !important',
      height: '24px',
      marginRight: '20px',
      minWidth: '0px',
      padding: '2px 8px',
      '& .MuiButton-label': {
        ...fonts.displayFonts.capsRegular,
        color: palette.level3.main,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        border: `1px solid ${palette.borderTrans.main}`,
        '& .MuiButton-label': {
          color: palette.level2.main,
        },
      },
      '&:active': {
        '& .MuiButton-label': {
          color: palette.ink.main,
        },
      },
      '& .MuiButton-startIcon': {
        marginLeft: '0',
        marginRight: '2px',
      },
      '& .MuiButton-endIcon': {
        marginLeft: '0',
        marginRight: '2px',
      },
      '&::after': {
        content: 'none'
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent !important',
        '& .MuiButton-label': {
          color: palette.level4.main,
        },
      },
    },
    onlyIcon: {
      padding: '2px 2px',
      '& .MuiButton-startIcon': {
        marginRight: '0',
      },
      '& .MuiButton-label': {
        width: '20px',
      }
    },
    onlyText: {
      padding: '0 8px',
    },
    selected: {
      backgroundColor: palette.bkg2.main,
      border: `1px solid ${palette.borderTrans.main}`,
      '& .MuiButton-label': {
        color: palette.level1.main,
      },
      '&:hover': {
        backgroundColor: palette.bkg3.main,
        borderColor: palette.borderMedium.main,
        '& .MuiButton-label': {
          color: palette.level1.main,
        }
      },
    },
    system1: {
      '& .MuiButton-label': {
        color: palette.system1.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.system1.main,
        },
      },
      '& $selected': {
        '& .MuiButton-label': {
          color: palette.system1.main,
        },
        '&:hover': {
          '& .MuiButton-label': {
            color: palette.system1.main,
          },
        },
      },
    },
    system2: {
      '& .MuiButton-label': {
        color: palette.system2.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.system2.main,
        },
      },
      '& $selected': {
        '& .MuiButton-label': {
          color: palette.system2.main,
        },
        '&:hover': {
          '& .MuiButton-label': {
            color: palette.system2.main,
          },
        },
      },
    },
    system3: {
      '& .MuiButton-label': {
        color: palette.system3.main,
      },
      '&:hover': {
        '& .MuiButton-label': {
          color: palette.system3.main,
        },
      },
      '& $selected': {
        '& .MuiButton-label': {
          color: palette.system3.main,
        },
        '&:hover': {
          '& .MuiButton-label': {
            color: palette.system3.main,
          },
        },
      },
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxTextButton' });

function TextButton ({
  children = null,
  className,
  disabled = false,
  IconComponent = null,
  onClick,
  selected = false,
  testId,
  variant = 'regular',
  ...props
}: TextButtonProps) {
  const classes = useStyles();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
  }

  return (
    <MaterialButton
      classes={{
        root: classes.root,
        label: classes.label,
        sizeLarge: classes.sizeLarge,
        sizeSmall: classes.sizeSmall,
      }}
      className={clsx([
        classes.button,
        ((children === '' || children === null) && IconComponent) && classes.onlyIcon,
        ((children !== '' || children !== null) && !IconComponent) && classes.onlyText,
        (variant !== 'regular') && classes[variant],
        selected && classes.selected,
        className
      ])}
      data-component="onx-text-button"
      data-test-id={testId}
      disabled={disabled}
      disableRipple
      onClick={handleClick}
      size="medium"
      startIcon={IconComponent && <IconComponent/>}
      variant="text"
      {...props}
    >
      {children}
    </MaterialButton>
  );
};

export default TextButton;
