import React from 'react';
import clsx from 'clsx';
import { defaultLightTheme, makeStyles } from '../../themes';
import { SwitchGroupProps } from '../../types';

const useStyles = makeStyles({
  verticalGroup: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: '4px',
    },
  },
  horizontalGroup: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:not(:last-child)': {
      marginRight: '4px',
    },
  },
}, { defaultTheme: defaultLightTheme, name: 'OnxSwitchGroup' });

function SwitchGroup(props: SwitchGroupProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        props.direction === 'vertical'
          ? classes.verticalGroup
          : classes.horizontalGroup,
        props.className
      )}
      data-component="onx-switch-group"
      data-test-id={props.testId}
    >
      {props.children}
    </div>
  );
}

export default SwitchGroup;
