import * as React from 'react'
import clsx from 'clsx';
import {
  Button as MaterialButton,
} from '@material-ui/core';
import { defaultLightTheme, makeStyles } from '../../themes';
import { fonts } from '../../constants';
import { ClassNameMap } from '../../types';
import { PopupButtonProps } from '../../types/popupButton';
import { useCallback } from 'react';

type Size = keyof typeof fonts.displayFonts;
type SizeStyles = Partial<Record<Size, object>>;
type TextButtonClassKey = 'button' | 'selected' | 'ellipsis' | 'italic' | Size;
type UseStyles = (props?: {}) => ClassNameMap<TextButtonClassKey>;
const fontSizeStyles: SizeStyles = Object.keys(fonts.displayFonts).reduce((styles: SizeStyles, size) => {
  styles[size as Size] = {
    '& .MuiButton-label': {
      ...fonts.displayFonts[size as Size]
    }
  }
  return styles
}, {})

const useStyles = makeStyles(({ palette }) => {
  return {
    button: {
      background: 'none',
      borderColor: 'transparent',
      boxShadow: 'none !important',
      marginRight: '20px',
      maxWidth: '252px',
      minWidth: '0px',
      '& .MuiButton-label': {
        color: palette.level2.main,
      },
      '&:hover': {
        background: 'none',
        '& .MuiButton-label': {
          color: palette.level1.main,
        },
        '& .MuiButton-endIcon': {
          color: palette.level1.main,
        }
      },
      '&:active': {
        '& .MuiButton-label': {
          color: palette.ink.main,
        },
      },
      '& .MuiButton-endIcon': {
        color: palette.level4.main,
        marginLeft: '0',
        marginRight: '2px',
      },
      '&::after': {
        content: 'none'
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent !important',
        '& .MuiButton-label': {
          color: palette.level4.main,
        },
      },
    },
    selected: {
      '& .MuiButton-label': {
        color: palette.level1.main,
      },
      '& .MuiButton-endIcon': {
        color: palette.level1.main,
      }
    },
    italic: {
      '& .MuiButton-label': {
        fontStyle: 'italic',
      },
    },
    ellipsis: {
      overflow: 'hidden',
      paddingRight: '4px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    ...fontSizeStyles as object
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxPopupButton' }) as UseStyles;

const PopupButton = React.forwardRef<HTMLButtonElement, PopupButtonProps>(({
  size = "medium",
  children = null,
  className,
  classes = {},
  disabled = false,
  EndIconComponent = null,
  italic = false,
  onClick,
  selected = false,
  testId,
  ...props
}, ref) => {
  const cls = useStyles();

  const {
    button: buttonCls,
    selected: selectedCls,
    italic: italicCls,
    ellipsis: ellipsisCls,
    ...restCls
  } = classes;
  
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
  }, [onClick]);

  return (
    <MaterialButton
      className={clsx([
        cls.button,
        buttonCls,
        cls[size],
        !italic && [cls.italic, italicCls],
        selected && [cls.selected, selectedCls],
        className
      ])}
      classes={restCls}
      data-component="onx-popup-button"
      data-test-id={testId}
      disabled={disabled}
      onClick={handleClick}
      endIcon={EndIconComponent && <EndIconComponent size={size}/>}
      variant="text"
      ref={ref}
      {...props}
    >
      <span className={clsx(cls.ellipsis, ellipsisCls)}>
        {children}
      </span>
    </MaterialButton>
  );
})

export default PopupButton;
