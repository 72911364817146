import * as React from 'react';
import { useState } from 'react';
import { PopupFilter } from '../../Filters';
import { Button } from '../../Button';
import { DropdownSelector } from '../../DropdownSelector';
import { Input } from '../../Input';
import { Popover } from '../../Popover';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { GridFilterDateRangeProps } from '../../../types';

const useStyles = makeStyles(() => {
  return {
    popover: {
      '& .MuiPopover-paper': {
        maxWidth: 'none',
      },
    },
    form: {
      display: 'flex',
      '& > *': {
        marginLeft: '8px',
        '&:first-child': {
          marginLeft: 0,
        },
      },
    },
  }
}, { name: 'OnxGridFilterDateRange', defaultTheme: defaultLightTheme});

function GridFilterDateRange ({
  className,
  condition,
  endDate,
  filterOptions,
  iconSize = 'xSmall',
  initialDate,
  onOpen,
  onClose,
  testId,
}: GridFilterDateRangeProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [filterCondition, setFilterCondition] = useState<string | undefined>(condition);
  const [filterInitialDate, setFilterInitialDateValue] = useState<object | string | number | Date | null | undefined>(initialDate);
  const [filterEndDate, setFilterEndDateValue] = useState<object | string | number | Date | null | undefined>(endDate);

  const classes = useStyles();

  const handleOpen = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
    onOpen && onOpen();
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    onClose && onClose(filterCondition, filterInitialDate, filterEndDate);
  }

  const handleChangeCondition = (newCondition: string | undefined) => {
    setFilterCondition(newCondition);
  
    if (newCondition !== 'between') {
      setFilterEndDateValue(null);
    }
  }

  const handleClear = () => {
    setFilterCondition(undefined);
    setFilterInitialDateValue(null);
    setFilterEndDateValue(null);
  }

  return (
    <PopupFilter
      active={!!filterInitialDate}
      className={className}
      data-component="onx-grid-filter-date-match"
      data-test-id={testId}
      onClick={handleOpen}
      size={iconSize}>
      <Popover
        anchorEl={anchorEl}
        className={classes.popover}
        label=""
        onClose={handleClose}
        open={open}>
        <div className={classes.form}>
          <DropdownSelector
            label="Condition"
            multiple={false}
            onChange={handleChangeCondition}
            items={filterOptions}
            size="medium"
            value={filterCondition} />
          <Input
            disableFuture={true}
            format="yyyy-MM-dd"
            label="Value"
            onChange={(date: Date | null) => { setFilterInitialDateValue(date) }}
            size="small"
            type="date"
            value={filterInitialDate} />
          {
            filterCondition === 'between' &&
              <Input
                disableFuture={true}
                format="yyyy-MM-dd"
                label="Value"
                onChange={(date: Date | null) => { setFilterEndDateValue(date) }}
                size="small"
                type="date"
                value={filterEndDate} />
          }
          <Button
            color="outline"
            onClick={handleClear}>
            Clear
          </Button>
        </div>
      </Popover>
    </PopupFilter>
  );
}

export default GridFilterDateRange;
