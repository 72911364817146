import * as React from 'react';
import clsx from 'clsx';
import { TableCell } from '@material-ui/core';
import GridFilter from './Filters/GridFilter';
import {
  SortDownIcon,
  SortUpIcon
} from '../Icon';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { GridCellHeadProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      ...fonts.displayFonts.colHeadM,
      borderBottomColor: palette.borderDivider.main,
      color: palette.level1.main,
      padding: '10px 12px',
      width: ({ width }: Pick<GridCellHeadProps, 'width'>) => width ?? 'auto',
    },
    pinnedLeft: {
      backgroundColor: palette.canvas.main,
      left: 0,
      position: 'sticky',
      zIndex: 2,
    },
    pinnedTop: {
      backgroundColor: palette.canvas.main,
      top: 0,
      position: 'sticky',
      zIndex: 2,
    },
    sort: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
    },
    small: {
      ...fonts.displayFonts.colHeadS,
      padding: '6px 8px',
    },
    content: {
      alignItems: 'center',
      display: 'flex',
    },
    alignCenter: {
      justifyContent: 'center',
    },
    alignRight: {
      justifyContent: 'flex-end',
    },
  };
}, { name: 'OnxGridCellHead', defaultTheme: defaultLightTheme});

const GridCellHead = React.forwardRef<HTMLTableCellElement, GridCellHeadProps>(function GridCellHead ({
  align = 'left',
  children,
  className,
  filterData,
  iconSize = 'xSmall',
  onFilter,
  onSort,
  pinned = false,
  pinnedLeft = false,
  pinnedTop = false,
  size = 'medium',
  sortOrder,
  testId,
  width,
  ...props
}, ref) {

  const classes = useStyles({ width });

  const handleSort = () => {
    onSort && onSort();
  }

  return (
    <TableCell
      {...props}
      align={align ? align : 'left'}
      className={className}
      classes={{
        root: clsx(
          classes.root,
          size === 'small' && classes.small,
          (pinned || pinnedLeft) && classes.pinnedLeft,
          pinnedTop && classes.pinnedTop,
        )
      }}
      data-component="onx-grid-cell"
      data-test-id={testId}
      ref={ref}>
      <div className={clsx(
        classes.content,
        align === 'center' && classes.alignCenter,
        align === 'right' && classes.alignRight,
      )}>
        {onSort ? (
          <span className={classes.sort} onClick={handleSort}>
            {sortOrder === 'asc' && <SortUpIcon size={iconSize} />}
            {sortOrder === 'desc' && <SortDownIcon size={iconSize} />}
            {children}
          </span>
        ) : children}
        {onFilter && filterData && <GridFilter filterData={filterData} iconSize={iconSize} onFilter={onFilter} />}
      </div>
    </TableCell>
  );
});

export default GridCellHead;
