import * as React from 'react';
import { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Collapse,
} from '@material-ui/core';
import { defaultLightTheme, makeStyles } from '../../../themes';
import MenuItem from '../MenuItem/MenuItem';
import { Checkbox } from '../../Checkbox';
import { MenuDownIcon } from '../../Icon';
import { fonts } from '../../../constants';
import {
  MenuListItem,
  MenuSectionProps
} from '../../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    section: {
      '&.MuiListItem-button': {
        ...fonts.displayFonts.capsMedium,
        color: palette.level3.main,
        '&:hover': {
          backgroundColor: palette.canvas.main,
        },
      },
    },
    collapsable: {
      '&.MuiListItem-button': {
        color: palette.level3.main,
        '&:hover': {
          backgroundColor: palette.bkg2.main,
          color: palette.level2.main,
          '& .MuiSvgIcon-root': {
            color: palette.level2.main,
          },
        },
      },
    },
    notCollapsed: {
      '&.MuiListItem-button': {
        color: palette.level4.main,
        '& .MuiSvgIcon-root': {
          color: palette.level4.main,
        },
      },
    },
    collapsed: {
      '&.MuiListItem-button': {
        color: palette.level3.main,
        '& .MuiSvgIcon-root': {
          color: palette.level3.main,
        },
      },
    },
    content: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
  };
}, { name: 'OnxMenuSection', defaultTheme: defaultLightTheme });

function MenuSection ({
  className,
  collapsable = false,
  item,
  itemClassName,
  MenuItemProps,
  multiple = false,
  onClick,
  selectedValue,
  testId,
}: MenuSectionProps) {
  const [collapsed, setCollapsed] = useState(true);

  const classes = useStyles();

  const toggleCollapse = useCallback(() => {
    if (collapsable) setCollapsed(!collapsed);
  }, [collapsable, setCollapsed, collapsed]);

  const isCheckboxSelected = useCallback((item: MenuListItem) => {
    return multiple && Array.isArray(selectedValue)
      ? selectedValue.includes(item.value)
      : selectedValue === item.value;
  }, [multiple, selectedValue]);

  return (
    <>
      <MenuItem
        className={clsx(
          classes.section,
          collapsable && classes.collapsable,
          (collapsable && !collapsed) && classes.notCollapsed,
          (collapsable && collapsed) && classes.collapsed,
          className
        )}
        data-component="onx-menu-section"
        data-test-id={testId}
        onClick={toggleCollapse}>
        <span className={classes.content}>
          {item.name}
        </span>
        {collapsable && <MenuDownIcon size="medium" /> }
      </MenuItem>
      {
        collapsable
          ? <Collapse in={collapsed}>
              {
                item.items?.map((childItem, childKey) => {
                  return (
                    <MenuItem
                      className={itemClassName}
                      key={childKey}
                      onClick={onClick}
                      value={childItem.value}
                      {...MenuItemProps}>
                      <span className={classes.content}>
                        {childItem.name}
                      </span>
                      {
                        multiple &&
                          <Checkbox
                            checked={isCheckboxSelected(childItem)}
                            size="large"
                            variant="single" />
                      }
                    </MenuItem>
                  );
                })
              }
            </Collapse>
          : item.items?.map((childItem, childKey) => {
            return (
              <MenuItem
                className={itemClassName}
                key={childKey}
                onClick={onClick}
                value={childItem.value}
                {...MenuItemProps}>
                <span className={classes.content}>
                  {childItem.name}
                </span>
                {
                  multiple &&
                    <Checkbox
                      checked={isCheckboxSelected(childItem)}
                      size="large"
                      variant="single" />
                }
              </MenuItem>
            );
          })
      }
    </>
  );
}

export default MenuSection;
