import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { FiltersSelectorProps } from '../../types';
import Button from '../Button/Button';
import TextButton from '../TextButton/TextButton';
import Modal from '../Modal/Modal';
import Checkbox from '../Checkbox/Checkbox';
import { defaultLightTheme, makeStyles } from '../../themes';

const useStyles = makeStyles({
  filters: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
    minWidth: '400px',
    maxHeight: '240px',
    overflowY: 'scroll',
  },
  formControlLabel: {
    marginLeft: '0px',
  },
  checkbox: {
    padding: '6px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '8px',
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxFilterModal' });

const FiltersModal: React.FC<FiltersSelectorProps> = ({
  className,
  onChange,
  options,
  testId,
  values,
}) => {
  const classes = useStyles();
  const [appliedFilters, setAppliedFilters] = useState(values)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)

  return (
    <div
      className={className}
      data-component="onx-filter-modal"
      data-test-id={testId}
    >
      <TextButton
        onClick={() => {
          setAppliedFilters(values)
          setIsModalOpen(true)
        }}
      >
        Add filter
      </TextButton>

      <Modal
        open={isModalOpen}
        title="Filters"
        onClose={closeModal}
      >
        <div className={classes.filters}>
          <FormControlLabel
            control={
              <Checkbox
                variant='single'
                checked={appliedFilters.length === options.length}
                indeterminate={appliedFilters.length > 0 && appliedFilters.length !== options.length}
                onChange={(event, checked) => (
                  setAppliedFilters(checked ? 
                    options.map(option => option.value)
                   : [])
                )}
                name="All"
                className={classes.checkbox}
              />
            }
            label="All"
            className={classes.formControlLabel}
          />
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  variant='single'
                  checked={appliedFilters.includes(option.value)}
                  onChange={(event, checked) => (
                    setAppliedFilters(checked ? [
                      ...appliedFilters,
                      option.value,
                    ] : appliedFilters.filter(filter => filter !== option.value))
                  )}
                  name={option.name}
                  className={classes.checkbox}
                />
              }
              label={option.name}
              className={classes.formControlLabel}
            />
          ))}
        </div>
        <div className={classes.buttons}>
          <Button
            color="outline"
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            color="system"
            onClick={() => {
              onChange(appliedFilters)
              closeModal()
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default FiltersModal;
