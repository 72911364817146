import React from 'react';
import clsx from 'clsx';
import { Popover as PopoverMui } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { fonts, opacities } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { PopoverPropsCustom } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  const isLight = palette.type === 'light';

  return {
    root: {
      marginTop: '15px',
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiPopover-paper': {
        backgroundColor: isLight
          ? palette.canvas.main
          : palette.bkg3.main,
        borderColor: palette.borderStrong.main,
        boxShadow: 'none',
        boxSizing: 'border-box',
        padding: '12px 20px',
      },
      '& .MuiIconButton-root': {
        padding: '0px',
      },
    },
    paper: {
      maxWidth: '300px',
      border: `1px solid ${palette.ink.main}${opacities[30]}`,
      backgroundColor: palette.canvas.main,
    },
    typography: {
      ...fonts.displayFonts.smallPara,
      color: palette.level1.main,
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxPopover' });

const Popover = ({
  anchorEl,
  children,
  className,
  label,
  link,
  onClose,
  open,
  testId,
  TypographyProps = {},
  ...props
}: PopoverPropsCustom) => {
  const classes = useStyles();
  const id = open ? 'simple-popover' : undefined;
  return (
    <PopoverMui
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      className={className}
      data-component="onx-popover"
      data-test-id={testId}
      id={id}
      onClose={onClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      {
        children || (
          <Typography
            {...TypographyProps}
            className={clsx(classes.typography, TypographyProps.className)}>
            {label} {link && <Link href={link?.web}>{link?.labelToShow}</Link>}
          </Typography>
        )
      }
    </PopoverMui>
  );
};

export default Popover;
