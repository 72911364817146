import React from 'react';
import clsx from 'clsx';
import { Button as MaterialButton } from '@material-ui/core';
import { defaultLightTheme, makeStyles } from '../../themes';
import { IconButtonProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {
  return {
    button: {
      background: 'none',
      border: 'none',
      boxShadow: 'none !important',
      height: 'auto',
      margin: 0,
      minWidth: 0,
      padding: 0,
      '& .MuiSvgIcon-root': {
        color: palette.level3.main,
      },
      '&:hover': {
        background: 'none',
        '& .MuiSvgIcon-root': {
          color: palette.level1.main,
        },
      },
      '&:active': {
        '& .MuiSvgIcon-root': {
          color: `${palette.system2.main} !important`,
        },
      },
      '& .MuiButton-startIcon': {
        margin: '0',
      },
      '&::after': {
        content: 'none'
      },
    },
    selected: {
      '& .MuiSvgIcon-root': {
        color: `${palette.system2.main} !important`,
      },
    },
    disabled: {
      '&.MuiButton-root.Mui-disabled': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiSvgIcon-root,': {
        color: `${palette.level4.main} !important`,
      },
      '&:active': {
        '& .MuiSvgIcon-root': {
          color: `${palette.level4.main} !important`,
        },
      },
    },
  }
}, { defaultTheme: defaultLightTheme, name: 'OnxIconButton' });

const IconButton = ({
  className,
  disabled = false,
  IconComponent,
  onClick,
  selected = false,
  size = 'medium',
  testId,
  ...props
}: IconButtonProps) => {
  const classes = useStyles();
  const { root: rootCls, disabled: disabledCls, ...restCls } = props.classes ?? {};
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
  }

  return (
    <MaterialButton
      {...props}
      classes={{
        root: clsx(classes.button, rootCls),
        disabled: clsx(classes.disabled, disabledCls),
        ...restCls
      }}
      className={clsx([
        selected && classes.selected,
        className
      ])}
      data-component="onx-icon-button"
      data-test-id={testId}
      disabled={disabled}
      onClick={handleClick}
      startIcon={<IconComponent size={size} />}
      variant="text"
    />
  );
};

export default IconButton;
