import * as React from 'react';
import { fonts } from '../../../constants';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { GenericSectionTabsProps } from '../../../types';
import { SectionTabs } from '..';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      minHeight: '42px',
    },
    indicator: {
      display: 'none',
    },
    labelContainer: {
      color: palette.level3.main,
      ...fonts.displayFonts.medium,
      textTransform: 'none',
    },
    tab: {
      backgroundColor: palette.bkg1.main,
      minHeight: '42px',
      padding: '11px 20px 10px 20px',
      '&:hover': {
        '& .MuiTab-wrapper > div': {
          color: palette.level2.main,
        },
        '& .MuiTab-wrapper > div::after': {
          background: palette.level3.main,
          bottom: '-3px',
          height: '1px',
        },
      },
      '&:first-child': {
        borderTopLeftRadius: '4px',
      },
    },
    activeTab: {
      '& .MuiTab-wrapper > div': {
        color: palette.level1.main,
      },
      '&:hover': {
        '& .MuiTab-wrapper > div': {
          color: palette.level1.main,
        },
      },
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxPanelTabs' });

function PanelTabs({
  className,
  testId,
  ...props
}: GenericSectionTabsProps) {

  const classes = useStyles();

  return <SectionTabs
    classes={classes}
    className={className}
    data-component="onx-panel-tabs"
    data-test-id={testId}
    {...props}
  />;
}

export default PanelTabs;
