import * as React from 'react';
import clsx from 'clsx';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { DialogContentHeadingProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {

  return {
    root: {
      ...fonts.displayFonts.xSmallStrong,
      color: palette.level2.main,
      fontFamily: fonts.fontFamily,
      padding: '6px 0',
    }
  };
}, { name: 'OnxDialogContentHeading', defaultTheme: defaultLightTheme });

const DialogContentHeading = React.forwardRef<HTMLDivElement, DialogContentHeadingProps>(function DialogContentHeading ({
  children,
  className,
  testId
}, ref) {

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        className
      )}
      data-component="onx-dialog-content-heading"
      data-test-id={testId}
      ref={ref}>
      {children}
    </div>
  )
})

export default DialogContentHeading;
