import * as React from 'react';
import clsx from 'clsx';
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
} from '@material-ui/core';
import {
  CheckboxOffIcon,
  CheckboxOnIcon,
  CheckboxMixIcon
} from '../Icon';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import {
  CheckboxProps,
  CheckboxIconsSizes,
} from '../../types';

const useStyles = makeStyles(theme => {
  const { palette } = theme;

  return {
    checkbox: {
      padding: 0,
      '&:hover': {
        background: 'none',
      },
      '&.Mui-checked, &.Mui-intermediate, &.MuiCheckbox-indeterminate': {
        '&:hover': {
          background: 'none',
        },
      },
    },
    single: {
      padding: '0 !important',
      '&:hover': {
        backgroundColor: 'transparent !important',
        '& .Mui-checked, & .Mui-intermediate, & .MuiCheckbox-indeterminate': {
          '& .MuiSvgIcon-root': {
            color: `${palette.level1.main} !important`,
          },
        },
      }
    },
    formControl: {
      borderRadius: '2px',
      color: palette.level3.main,
      marginLeft: 0,
      marginRight: '4px',
      padding: '2px 4px 2px 4px',
      '&:hover': {
        backgroundColor: palette.hov1.main,
        color: palette.level2.main,
        '& .MuiSvgIcon-root': {
          color: palette.level3.main,
        },
        '& .Mui-checked, & .Mui-intermediate, & .MuiCheckbox-indeterminate': {
          '& .MuiSvgIcon-root': {
            color: palette.level2.main,
          },
          '& ~ .MuiFormControlLabel-label': { // Label is a sibling of the checkbox checked element, though we need to use ~.
            color: palette.level1.main,
          },
        },
      },
      '& .MuiSvgIcon-root': {
        color: palette.level4.main,
      },
      '& .MuiFormControlLabel-label': {
        ...fonts.displayFonts.medium,
      },
      '& .Mui-checked, & .Mui-intermediate, & .MuiCheckbox-indeterminate': {
        '& .MuiSvgIcon-root': {
          color: palette.level2.main,
        },
        '& ~ .MuiFormControlLabel-label': { // Label is a sibling of the checkbox checked element, though we need to use ~.
          color: palette.level1.main,
        },
      },
    },
    mediumFormControl: {
      '& .MuiFormControlLabel-label': {
        ...fonts.displayFonts.small,
      },
      '& .MuiButtonBase-root': {
        paddingLeft: 0,
      },
    },
    labelSpacing: {
      '& .MuiFormControlLabel-label': {
        margin: '0 4px',
      },
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxCheckbox' });

function Checkbox({
  className,
  classes: inheritedClasses,
  indeterminate = false,
  label = '',
  size = 'large',
  testId,
  variant,
  ...props
}: CheckboxProps) {
  const classes = useStyles();
  const iconSize: CheckboxIconsSizes = size === 'large' ? 'medium' : 'small';

  return (
    <FormControlLabel
      {...props}
      control={
        <MaterialCheckbox
          classes={{
            root: clsx(
              classes.checkbox,
              inheritedClasses?.root
            ),
          }}
          indeterminate={indeterminate}
          icon={<CheckboxOffIcon size={iconSize} />}
          checkedIcon={<CheckboxOnIcon size={iconSize} />}
          indeterminateIcon={<CheckboxMixIcon size={iconSize} />}
        />
      }
      classes={{
        root: clsx(
          classes.formControl,
          size === 'medium' && classes.mediumFormControl,
          label !== '' && classes.labelSpacing,
          variant === 'single' && classes.single,
          className
        ),
        ...inheritedClasses,
      }}
      data-component="onx-checkbox"
      data-test-id={testId}
      label={variant === 'single' ? '' : label}
    />
  );
}

export default Checkbox;
