export const fonts = {
  fontFamily: '"Roboto", sans-serif',
  controlComponents: {
    grid: {
      colHead: {
        large: {
          fontSize: '11px',
          fontWeight: 500,
          textTransform: 'none',
          letterSpacing: '0.06em',
          lineHeight: '24px',
        },
      },
      dataCell: {
        large: {
          fontSize: '13px',
          fontWeight: 400,
          textTransform: 'none',
          letterSpacing: '0.01em',
          lineHeight: '24px',
        },
      },
    },
  },
  displayFonts: {
    xxSmall: {
      fontSize: '10px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '12px',
    },
    xxSmallStrong: {
      fontSize: '10px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '12px',
    },
    xSmall: {
      fontSize: '12px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '14px',
    },
    xSmallItalic: {
      fontSize: '12px',
      fontStyle: 'italic',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '14px',
    },
    xSmallPara: {
      fontSize: '12px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '16px',
    },
    xSmallStrong: {
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '14px',
    },
    xSmallStrongItalic: {
      fontSize: '12px',
      fontStyle: 'italic',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '14px',
    },
    small: {
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.0025em',
      lineHeight: '17px',
    },
    smallItalic: {
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.0025em',
      lineHeight: '17px',
    },
    smallPara: {
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '20px',
    },
    smallStrong: {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.0025em',
      lineHeight: '17px',
    },
    smallStrongItalic: {
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.0025em',
      lineHeight: '17px',
    },
    medium: {
      fontSize: '16px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '20px',
    },
    mediumItalic: {
      fontSize: '16px',
      fontStyle: 'italic',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '20px',
    },
    mediumStrong: {
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '20px',
    },
    mediumStrongItalic: {
      fontSize: '16px',
      fontStyle: 'italic',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '20px',
    },
    large: {
      fontSize: '20px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '24px',
    },
    largeStrong: {
      fontSize: '20px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '24px',
    },
    xLarge: {
      fontSize: '24px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '28px',
    },
    xLargeStrong: {
      fontSize: '24px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '28px',
    },
    xxLarge: {
      fontSize: '28px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '33px',
    },
    xxLargeStrong: {
      fontSize: '28px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '33px',
    },
    xxxLarge: {
      fontSize: '32px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '38px',
    },
    xxxLargeStrong: {
      fontSize: '32px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '38px',
    },
    floatingLabel: {
      fontSize: '12px',
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: '0.01em',
      lineHeight: '12px',
    },
    capsSmall: {
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
      lineHeight: '14px',
    },
    capsMedium: {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
      lineHeight: '17px',
    },
    capsRegular: {
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
      lineHeight: '17px',
    },
    capsLarge: {
      fontSize: '15px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
      lineHeight: '20px',
    },
    colHeadXS: {
      fontSize: '9px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '0.02em',
      lineHeight: '12px',
    },
    colHeadS: {
      fontSize: '10px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
      lineHeight: '12px',
    },
    colHeadM: {
      fontSize: '10px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '0.06em',
      lineHeight: '14px',
    },
    counterMedium: {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'none',
      letterSpacing: '0.06em',
      lineHeight: '15px',
    },
  },
} as const;

export default fonts;
