import * as React from 'react';
import { Dialog as MuiDialog } from '@material-ui/core';
import { opacities } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { DialogProps } from '../../types';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette }) => {

  return {
    paper: {
      backgroundColor: palette.canvas.main,
      border: `1px solid ${palette.borderStrong.main}`,
      borderRadius: '4px',
      boxSizing: 'border-box',
      width: '292px',

      '& *': {
        boxSizing: 'inherit',
      },

      '&.MuiPaper-elevation24': {
        boxShadow: `0 0 50px ${palette.ink.main}${opacities[25]}`,
      } 
    },
  }
}, { name: 'OnxDialog', defaultTheme: defaultLightTheme });

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(function Dialog ({
  children,
  classes,
  className,
  open = false,
  testId,
  ...props
}, ref) {

  const cls = useStyles();

  return (
    <MuiDialog
      {...props}
      className={className}
      classes={{
        ...classes,
        paper: clsx(cls.paper, classes?.paper),
      }}
      data-component="onx-dialog"
      data-test-id={testId}
      open={open}
      ref={ref}>
      {children}
    </MuiDialog>
  );

});

export default Dialog;
