import * as React from 'react';
import { DialogActions } from '@material-ui/core';
import { defaultLightTheme, makeStyles } from '../../themes';
import { DialogFooterProps } from '../../types';

const useStyles = makeStyles(({ palette }) => {

  return {
    root: {
      alignItems: 'center',
      backgroundColor: palette.canvas.main,
      borderTop: `1px solid ${palette.borderTrans.main}`,
      display: 'flex',
      height: '39px',
      justifyContent: 'space-between',
      padding: '0 16px',
    }
  }
}, { name: 'OnxDialogFooter', defaultTheme: defaultLightTheme})

const DialogFooter = React.forwardRef<HTMLDivElement, DialogFooterProps>(function DialogFooter ({
  children,
  className,
  testId
}, ref) {

  const classes = useStyles();

  return (
    <DialogActions
      className={className}
      classes={{
        root: classes.root,
      }}
      data-component="onx-dialog-footer"
      data-test-id={testId}
      ref={ref}>
      {children}
    </DialogActions>
  )
})

export default DialogFooter;
