import React from 'react';
import clsx from 'clsx';
import MuiChip from '@material-ui/core/Chip';
import {
  Icons,
  IconsType,
  CloseIcon,
} from '../Icon';
import { Tooltip } from '../Tooltip';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import { ChipProps } from '../../types';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    background: `${palette.canvas.main} !important`,
    border: `1px solid ${palette.borderTrans.main}`,
    cursor: 'pointer',
    height: '32px',
    '&:hover': {
      background: `${palette.hov1.main} !important`,
      '& .MuiChip-label': {
        color: palette.level1.main
      }
    },
  },
  label: {
    alignItems: 'center',
    color: palette.level2.main,
    display: 'flex',
    fontFamily: fonts.fontFamily,
    fontStyle: 'normal',
    textAlign: 'center',
    ...fonts.displayFonts.small,
  },
  deleteIcon: {
    color: palette.level4.main,
    cursor: 'pointer',
    height: '1em',
    marginLeft: '-8px',
    marginRight: '12px',
    width: '1em',
    '&:hover': {
      color: palette.level1.main,
    },
  },
  chipRoot: {
    borderRadius: '18px',
    height: 'unset',
    userSelect: 'none',
    '& .MuiChip-label': {
      padding: '9px 4px 8px 12px',
      ...fonts.displayFonts.medium,
    },
    '&.MuiChip-sizeSmall': {
      borderRadius: '10px',
      '& .MuiChip-label.MuiChip-labelSmall': {
        padding: '4px 4px 3px 10px',
        ...fonts.displayFonts.small,
      },
    },
    '& .MuiChip-deleteIcon': {
      height: '20px',
      margin: '0px 12px 0px 0px',
      width: '20px',
      '&.MuiChip-deleteIconSmall': {
        height: '17px',
        margin: '0px 10px 0px 0px',
        width: '17px',
      },
    },
  },
  active: {
    background: `${palette.hov2.main} !important`,
    color: palette.level1.main,
    '& .MuiChip-deleteIcon': {
      color: palette.level4.main,
    },
  },
  medium: {
    height: '37px',
  },
  small: {
    height: '24px',
  },
}), { defaultTheme: defaultLightTheme, name: 'OnxChip' });

function Chip({
  active = false,
  className,
  deleteTooltipTitle = 'Delete',
  disabled = false,
  iconName,
  label = '',
  onClick,
  onDelete,
  size = 'medium',
  testId,
  ...props
}: ChipProps) {
  const classes = useStyles();
  const Icon: IconsType[keyof IconsType] = iconName && Icons[iconName] ? Icons[iconName] : CloseIcon;
  return (
    <MuiChip
      label={label}
      disabled={disabled}
      size={size}
      {...onClick && { onClick }}
      {...onDelete && { onDelete }}
      className={clsx(
        classes.chipRoot,
        active && classes.active,
        classes[size],
        className
      )}
      classes={{
        root: classes.root,
        label: classes.label,
        deleteIcon: classes.deleteIcon,
      }}
      data-component="onx-chip"
      data-test-id={testId}
      deleteIcon={(
        <Tooltip placement="top" title={deleteTooltipTitle}>
          <Icon />
        </Tooltip>
      )}
      {...props}
    />
  );
}

export default Chip;
