import * as React from 'react';
import clsx from 'clsx';
import { fonts } from '../../../constants';
import { defaultLightTheme, makeStyles } from '../../../themes';
import { BaseProps } from '../../../types';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    root: {
      background: palette.canvas.main,
      borderLeft: `1px solid ${palette.border.main}`,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: '14px 24px 20px',
      width: 300,
    },
    title: {
      color: palette.ink.main,
      fontFamily: `${fonts.fontFamily}`,
      ...fonts.displayFonts.mediumStrong,
      margin: 0,
      marginBottom: 14,
    },
    contentElement: {
      margin: '6px 0 14px 0',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '6px',
    },
    childButton: {
      flex: 1,
      '& .MuiButton-root': {
        width: '100%',
      },
      '&:last-child': {
        marginLeft: '16px',
      },
    },
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxControlDrawer' });

export interface ControlDrawerProps extends BaseProps<HTMLDivElement, 'root' | 'buttonContainer' | 'title'> {
  title?: string;
  buttons?: React.ReactNode | React.ReactNode[];
}

function ControlDrawer(props: ControlDrawerProps) {
  const {
    buttons,
    children,
    className,
    classes: inheritedClasses = {},
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(
        className,
        classes.root,
        inheritedClasses.root
      )}
    >
      {props.title ? (
        <h3 className={clsx(
            classes.title,
            inheritedClasses.title
          )}
        >
          {props.title}
        </h3>
      ) : null}
      {
        Array.isArray(children)
          ? children.map((childElement, key) => (
            <div className={classes.contentElement} key={key}>
              {childElement}
            </div>
          ))
          : children
      }
      {
        buttons &&
          <div
            className={clsx(
              classes.buttonContainer,
              inheritedClasses.buttonContainer
            )}
          >
            {
              Array.isArray(buttons)
                ? buttons.map((childButton, key) => (
                  <div className={classes.childButton} key={key}>
                    {childButton}
                  </div>
                ))
                : buttons
            }
          </div>
        }
    </div>
  );
}

export default ControlDrawer;
