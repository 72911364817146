import * as React from 'react';
import {
  useMemo,
  useState
} from 'react';
import clsx from 'clsx';
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import { DatePicker, TimePicker } from '../DatePicker';
import {
  HideIcon,
  ShowIcon,
} from '../Icon';
import { IconButton } from '../IconButton';
import { fonts } from '../../constants';
import { defaultLightTheme, makeStyles } from '../../themes';
import {
  InputPropsCustom,
  NumberFormatCustomProps
} from '../../types';

type InputStyleProps = Pick<InputPropsCustom, 'width'>;

const useStyles = makeStyles<InputStyleProps>(({ palette }) => {
  return {
    general: {
      color: palette.level1.main,
      margin: 0,
      '&:hover': {
        '& .MuiInputLabel-root': {
          color: palette.borderStrong.main,
          '&.Mui-disabled': {
            color: `${palette.level4.main} !important`,
          },
          '&.Mui-focused': {
            color: palette.system2.main,
          },
          '&.Mui-error': {
            color: palette.alert1.main,
          },
        },
      },
      '& .MuiOutlinedInput-root': {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.borderStrong.main,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.system2.main,
            },
          },
          '&.Mui-disabled': {
            '&.Mui-error': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${palette.level4.main} !important`,
              },
            },
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${palette.alert1.main} !important`,
            },
          },
          '& .MuiInputAdornment-positionEnd': {
            '& .MuiTypography-colorTextSecondary': {
              color: palette.level2.main,
            },
            '& .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root': {
              color: palette.level2.main,
            },
          },
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${palette.level4.main} !important`,
          },
          '& .MuiInputAdornment-positionEnd': {
            '& .MuiTypography-colorTextSecondary': {
              color: `${palette.level4.main} !important`,
            },
            '& .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root': {
              color: `${palette.level4.main} !important`,
            },
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.system2.main,
          },
        },
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${palette.alert1.main} !important`,
          },
        },
        '& .MuiInputAdornment-root': {
          height: 'auto',
          marginLeft: '8px',
          '& .MuiTypography-root': {
            ...fonts.displayFonts.medium,
          },
        },
      },
      '& .MuiInputLabel-outlined': {
        ...fonts.displayFonts.medium,
        transform: 'translate(16px, 12px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(1)',
          ...fonts.displayFonts.floatingLabel,
        },
        '&.MuiFormLabel-filled': {
          color: palette.level3.main,
        },
        '&.Mui-disabled': {
          color: palette.level4.main,
          '&.Mui-error': {
            color: palette.level4.main,
          },
        },
        '&.Mui-focused': {
          color: palette.system2.main,
        },
        '&.Mui-error': {
          color: palette.alert1.main,
        },
      },
    },
    small:  {
      width: ({ width }) => width ? width : '200px',
      '& .MuiOutlinedInput-root': {
        padding: '9px 12px 8px 16px',
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(16px, 9px) scale(1)',
      },
    },
    medium: {
      width: ({ width }) => width ? width : '252px',
      '& .MuiInputBase-input': {
        ...fonts.displayFonts.medium,
      },
    },
    datePicker: {
      '& .MuiIconButton-root': {
        '&:hover': {
          background: 'none',
        },
      },
      '& .MuiSvgIcon-root': {
        ...fonts.displayFonts.medium,
        color: palette.level2.main,
      },
      '&:hover': {
        '& .MuiInputLabel-root': {
          color: palette.level1.main,
        },
        '& .MuiFormLabel-filled': {
          color: palette.borderStrong.main,
        },
      },
      '& .MuiFormHelperText-root': { // This just hide the error `p` tag since there's no a prop way to hide it
        display: 'none',
      },
    },
    alignRight: {
      '& .MuiInputBase-input': {
        textAlign: 'right',
      },
    },
    hasValue: {
      '& .MuiInputAdornment-positionEnd': {
        '& .MuiTypography-colorTextSecondary': {
          color: palette.level2.main,
        },
      },
      '& .MuiSvgIcon-root': {
        color: palette.level2.main,
      },
    }
  };
}, { defaultTheme: defaultLightTheme, name: 'OnxInput' });

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, name, onChange, value, ...other } = props;
  return (
    <NumberFormat
      {...other}
      value={typeof value === 'object' ? '' : value}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
}

const Input = React.forwardRef<HTMLDivElement, InputPropsCustom>(function Input({
  className,
  defaultValue,
  disabled = false,
  disableFuture = false,
  error = false,
  format = 'dd-MMM-yyyy',
  isAlignRight = false,
  label = '',
  onChange,
  placeholder = '',
  size = 'medium',
  suffix = '',
  testId,
  type = 'text',
  value = '',
  width,
  ...props
}, ref) {
  const [showPassword, setShowPassword] = useState(false);

  const dateValue = useMemo(() => {
    return value instanceof Date ? value : null;
  }, [value])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const classes = useStyles({ width });

  return type === 'date' ? (
    <DatePicker
      ref={ref}
      {...props}
      className={clsx(
        classes.general,
        size === 'small' && classes.small,
        size === 'medium' && classes.medium,
        classes.datePicker,
        className,
      )}
      disabled={disabled}
      disableFuture={disableFuture}
      format={format}
      label={label}
      onChange={onChange || (() => {})}
      size={size}
      value={dateValue}
      variant="inline"
    />
  ) : type === 'time' ? (
    <TimePicker
      ref={ref}
      {...props}
      className={clsx(
        classes.general,
        size === 'small' && classes.small,
        size === 'medium' && classes.medium,
        classes.datePicker,
        className,
      )}
      disabled={disabled}
      format={format}
      label={label}
      onChange={onChange || (() => {})}
      size={size}
      value={dateValue}
      variant="inline"
    />
  ) : (
    <TextField
      ref={ref}
      {...props}
      className={clsx(
        classes.general,
        size === 'small' && classes.small,
        size === 'medium' && classes.medium,
        (suffix || isAlignRight) && classes.alignRight,
        !!value && classes.hasValue,
        className,
      )}
      data-component="onx-input"
      data-test-id={testId}
      {...(defaultValue && { defaultValue })}
      disabled={disabled}
      error={error}
      label={label}
      {...(onChange && { onChange })}
      placeholder={placeholder}
      size={size}
      type={type === 'password'
        ? showPassword
          ? 'text'
          : 'password'
        : type !== 'number'
          ? type
          : 'text'
      }
      {...(value !== undefined && { value })}
      variant="outlined"
      InputProps={{
        endAdornment: type === 'password' ? (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} size="medium" IconComponent={showPassword ? ShowIcon : HideIcon} />
          </InputAdornment>
        ) : suffix ? (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ) : undefined,
        inputComponent: type === 'number' ? (NumberFormatCustom as any) : undefined,
      }}
    />
  );
});

export default Input;
